<template>
  <v-col cols="12" md="6" sm="12">
    <v-card class="personio-overview__card mh-544">
      <div class="personio-overview__card-title mb-4">Mapped values ({{ mappedValues.length }})</div>
      <v-simple-table fixed-header height="440px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left personio-overview__th">
                <span class="text--blue">Degura Value</span>
              </th>
              <th class="text-left personio-overview__th">
                <span class="text--blue">Company value</span>
              </th>
              <th class="text-left personio-overview__th">
                <!--                        <span class="text&#45;&#45;blue">Value(s)</span>-->
              </th>
            </tr>
          </thead>
          <tbody v-for="(row, index) in mappedValues" :key="row.degura_field + index">
            <tr v-for="companyValue in row.company_values">
              <td class="personio-overview__td personio-overview__td--small v-card--no-border">
                <span v-if="getKeyByValue(row.mapping, companyValue)">{{
                  getKeyByValue(row.mapping, companyValue)
                }}</span>
                <span v-else class="warning-color">Not mapped</span>
              </td>
              <td class="personio-overview__td personio-overview__td--small v-card--no-border">
                <span v-if="getKeyByValue(row.mapping, companyValue)">{{ companyValue }}</span>
                <span v-else class="warning-color">{{ companyValue }}</span>
              </td>
              <td
                class="personio-overview__td personio-overview__td--small v-card--no-border"
                style="color: rgba(80, 90, 104, 0.5) !important"
              >
                <span v-if="getKeyByValue(row.mapping, companyValue)">{{ row.degura_field }}</span>
                <span v-else class="warning-color">{{ row.degura_field }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="isEdit" class="personio-overview__edit" @click="openEditModal">Edit</div>
    </v-card>
  </v-col>
</template>

<script>
import { getCurrentInstance, ref, toRefs } from '@vue/composition-api';
import { getDeguraEnums, getPersonioFields } from '@/api/personio-connector';

export default {
  props: ['personioCompany', 'isEdit'],
  name: 'MappedValues',
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const mappedValues = ref([]);
    const personioValues = ref([]);
    const deguraEnums = ref([]);

    const { personioCompany } = toRefs(props);

    const getPersonioValues = () => {
      return new Promise((resolve, reject) => {
        getPersonioFields(personioCompany.value.portalCompanyId)
          .then((res) => {
            personioValues.value = res.data.fields;
            resolve();
          })
          .catch((e) => reject(e));
      });
    };

    const getAllDeguraEnumValues = () => {
      return new Promise((resolve, reject) => {
        getDeguraEnums()
          .then((res) => {
            deguraEnums.value = res.data;
            resolve();
          })
          .catch((e) => reject(e));
      });
    };

    const fetchExistingValueMapping = () => {
      const promiseArr = [];
      promiseArr.push(getPersonioValues());
      promiseArr.push(getAllDeguraEnumValues());

      Promise.all(promiseArr).then(() => {
        const existingValues = personioCompany.value.configJson.fieldEnumMapping;
        const mappedFields = personioCompany.value.configJson.targetToSourceFieldMapping;
        const filterEnumFields = Object.keys(mappedFields).filter((i) => Object.keys(deguraEnums.value).includes(i));
        const mappedEnumFields = filterEnumFields.map((key) => ({
          degura_field: key,
          company_field: mappedFields[key],
        }));

        mappedValues.value = mappedEnumFields.map((field) => {
          const companyValuesIndex = personioValues.value.findIndex((v) => v.name === field.company_field);
          return {
            ...field,
            degura_values: deguraEnums.value[field.degura_field],
            company_values: personioValues.value[companyValuesIndex].values.map((item) =>
              !item ? 'Not available' : item,
            ),
            mapping: deguraEnums.value[field.degura_field].reduce((a, v) => {
              if (!existingValues[field.degura_field]) {
                vm.$emit(
                  'newFieldAdded',
                  `${field.degura_field} field was added, so please map also the values for this field`,
                );
                return openEditModal();
              }
              const existingMapping =
                existingValues && Object.keys(existingValues).length > 0
                  ? Object.keys(existingValues[field.degura_field])
                      ?.filter((key) => existingValues[field.degura_field][key] === v)
                      .map((item) => (!item ? 'Not available' : item))
                  : '';

              return {
                ...a,
                [v]: existingMapping,
              };
            }, {}),
          };
        });
      });
    };

    const getKeyByValue = (object, value) => {
      return Object.keys(object).find((key) => object[key].includes(value));
    };

    const openEditModal = () => {
      vm.$emit('changeConfigModal');
    };

    fetchExistingValueMapping();

    return {
      getKeyByValue,
      openEditModal,
      mappedValues,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/pages/_dashboard.scss';
</style>
