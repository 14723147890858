<template>
  <v-col cols="12" md="6" sm="12">
    <v-card class="personio-overview__card mh-544">
      <div class="personio-overview__card-title mb-4">
        Mapped fields <span v-if="mappedFields.length">({{ mappedFields.length }})</span>
      </div>
      <v-simple-table fixed-header height="440px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left personio-overview__th">
                <span class="text--blue">Degura field</span>
              </th>
              <th class="text-left personio-overview__th">
                <span class="text--blue">Company field</span>
              </th>
              <th class="text-left personio-overview__th">
                <!--                        <span class="text&#45;&#45;blue">Value(s)</span>-->
              </th>
            </tr>
          </thead>
          <tbody v-if="personioCompany">
            <tr v-for="(item, index) in mappedFields" :key="index + 'f'">
              <td class="personio-overview__td personio-overview__td--small v-card--no-border">
                {{ item.degura_field }}
              </td>
              <td class="personio-overview__td personio-overview__td--small v-card--no-border">
                {{ item.company_field }}
              </td>
              <td
                class="personio-overview__td personio-overview__td--small v-card--no-border"
                style="color: rgba(80, 90, 104, 0.5) !important"
              >
                {{ item.required ? 'Mandatory' : 'Optional' }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div v-if="isEdit" class="personio-overview__edit" @click="openEditModal">Edit</div>
    </v-card>
  </v-col>
</template>

<script>
import { getCurrentInstance, ref, toRefs } from '@vue/composition-api';
import { PERSONIO_REQUIRED_FIELDS } from '@/constants';

export default {
  props: ['personioCompany', 'isEdit'],
  name: 'MappedFields',
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const mappedFields = ref([]);

    const { personioCompany } = toRefs(props);

    mappedFields.value = Object.entries(personioCompany.value.configJson.targetToSourceFieldMapping).map((i) => {
      return {
        degura_field: i[0],
        company_field: i[1],
        required: PERSONIO_REQUIRED_FIELDS.includes(i[0]),
      };
    });

    const openEditModal = () => {
      vm.$emit('changeConfigModal');
    };

    return {
      openEditModal,
      mappedFields,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/pages/_dashboard.scss';
</style>
