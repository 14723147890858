<template>
  <div class="personio">
    <v-card
      class="personio-overview__card mh-260 mt-6"
      :class="
        editMode
          ? 'personio-overview__card-editing'
          : '' || !companyHasValidPersonioCreds
          ? 'personio-overview__card-invalid'
          : ''
      "
    >
      <v-alert
        v-model="alert"
        close-text="Close Alert"
        :color="companyHasValidPersonioCreds ? '#039181' : '#DD0000'"
        dark
        dismissible
        class="alert-personio pa-2"
      >
        <div v-if="companyHasValidPersonioCreds" class="text-sm">You changed the credentials successfully!</div>
        <div v-else class="text-sm">
          The connection to Personio was not successful! Please check the ID and secret again.
        </div>
      </v-alert>
      <div class="personio-overview__card-label personio-overview__card-label--left">
        <div class="text-center">
          <v-icon size="105" :color="companyHasValidPersonioCreds ? '#039181' : '#FF0505'">{{
            icons.mdiShieldLock
          }}</v-icon>
          <div class="personio-overview__card-subtitle mt-3">
            <span v-if="companyHasValidPersonioCreds">Successfully connected</span>
            <span v-else>Not connected</span>
          </div>
        </div>
      </div>

      <div class="personio-overview__card-content personio-overview__card-content--right">
        <div class="personio-overview__card-title">Credentials</div>
        <v-form ref="personioCredsForm" v-model="isFormValid" class="personio-step__auth-form">
          <v-text-field
            v-model="personioId"
            :type="isPersonioIdVisible ? 'text' : 'password'"
            :append-icon="isPersonioIdVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            outlined
            dense
            hide-details
            label="Personio ID"
            class="mb-6 mt-8"
            @click:append="isPersonioIdVisible = !isPersonioIdVisible"
            @input="saveChangesAllowed = true"
            @focus="editMode = true"
          ></v-text-field>
          <v-text-field
            v-model="personioSecret"
            :type="isPersonioSecretVisible ? 'text' : 'password'"
            :append-icon="isPersonioSecretVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            outlined
            dense
            hide-details
            label="Personio Secret"
            class="mb-6"
            @click:append="isPersonioSecretVisible = !isPersonioSecretVisible"
            @input="saveChangesAllowed = true"
            @focus="editMode = true"
          ></v-text-field>
          <v-btn
            :disabled="!saveChangesAllowed || (personioId && personioSecret == '')"
            class="personio-overview__btn"
            color="primary"
            @click="onChange"
          >
            Save changes
          </v-btn>
        </v-form>
      </div>
    </v-card>
    <ModalWindow :is-open="changePersonioCredsModal" warning>
      <template #content>
        <p class="text-base">Are you sure that you want to overwrite the personio crendentials?</p>
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="changePersonioCredsModal = false"> Cancel </v-btn>
        <v-btn color="primary" @click="updatePersonioCredentials"> Yes </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from '@vue/composition-api';
import { mdiEyeOffOutline, mdiEyeOutline, mdiShieldLock } from '@mdi/js';
import { postValidateCredentials, putUpdatePersonio } from '@/api/personio-connector';
import ModalWindow from '@/components/modal/ModalWindow.vue';
import { ADMIN_ACTIVITY_TYPE } from '@/constants';

export default {
  name: 'PersonioConnect',
  components: { ModalWindow },
  props: {
    personioCompany: {},
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;
    const isFormValid = ref(false);
    const isPersonioIdVisible = ref(false);
    const isPersonioSecretVisible = ref(false);
    const personioCredsForm = ref(null);
    const personioId = ref('');
    const personioSecret = ref('');
    const changePersonioCredsModal = ref(false);
    const companyHasValidPersonioCreds = ref(true);
    const saveChangesAllowed = ref(true);
    const editMode = ref(false);
    const onChange = () => {
      changePersonioCredsModal.value = true;
    };
    const alert = ref(false);
    const updatePersonioCredentials = async () => {
      changePersonioCredsModal.value = false;
      const userName =
        userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : `${userData.id}`;

      const credentialsValid = await postValidateCredentials({
        clientId: personioId.value,
        clientSecret: personioSecret.value,
      }).catch(() => {
        vm.$store.commit('showMessage', {});
      });
      if (!credentialsValid) {
        companyHasValidPersonioCreds.value = false;
        saveChangesAllowed.value = true;
        alert.value = true;
        vm.$emit('recordAction', {
          detail: { msg: `${userName}'s attempt to change the credentials wasn't successful` },
          type: ADMIN_ACTIVITY_TYPE.ERROR,
        });
      } else {
        const payload = {
          portalCompanyId: vm.$route.params.id,
          clientId: personioId.value,
          clientSecret: personioSecret.value,
        };

        putUpdatePersonio(vm.$route.params.id, payload).then(() => {
          alert.value = true;
          editMode.value = false;
          companyHasValidPersonioCreds.value = true;
          saveChangesAllowed.value = false;

          vm.$emit('recordAction', {
            detail: { msg: `${userName} changed credentials` },
            type: ADMIN_ACTIVITY_TYPE.WARNING,
          });
        });
      }
    };

    const reset = () => {
      personioSecret.value = '';
      personioId.value = '';
    };

    onMounted(() => {
      if (!props.personioCompany.portalCompanyId) {
        saveChangesAllowed.value = false;
        alert.value = true;
        companyHasValidPersonioCreds.value = false;
      }
    });

    return {
      personioCredsForm,
      isFormValid,
      personioId,
      personioSecret,
      isPersonioSecretVisible,
      isPersonioIdVisible,
      saveChangesAllowed,
      onChange,
      reset,
      changePersonioCredsModal,
      postValidateCredentials,
      updatePersonioCredentials,
      companyHasValidPersonioCreds,
      alert,
      editMode,

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiShieldLock,
      },
    };
  },
};
</script>

<style lang="scss">
.personio {
  &-overview {
    &__btn {
      border-radius: 3px !important;
      min-width: 165px !important;
    }

    &__card {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      background: #ffffff;
      border-radius: 3px !important;
      border: none !important;
      padding: 16px;
      max-height: 544px;
      overflow: auto;
      &-editing {
        filter: drop-shadow(0px 0px 5px #2830ce) !important;
      }
      &-invalid {
        filter: drop-shadow(0px 0px 5px #dd0000) !important;
      }
      &-schedule {
        margin-top: 36px;
        width: calc(100% - 245px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      &-subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #505a68;
      }
      &-content {
        &--right {
          padding-left: 228px;
        }
      }
      &-label {
        background: rgba(137, 137, 137, 0.1);
        border-radius: 0px 3px 3px 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 213px;
        height: 260px;
        &--left {
          left: 0;
        }
      }
      &-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #505a68;
      }
      &-tile {
        height: 153px;
        width: calc(33% - 7px);
        padding: 27px 51px;
        padding-bottom: 6px;
        background: rgba(137, 137, 137, 0.1);
        border-radius: 5px;
        text-align: center;
      }
      &.mh-544 {
        min-height: 544px;
      }
      &.mh-260 {
        min-height: 260px;
        max-height: 260px;
      }
    }
  }
}
.alert-personio {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  .v-alert__wrapper {
    align-items: flex-start !important;
  }
  .v-btn {
    margin-top: -5px !important;
  }
  .v-icon {
    height: 16px !important;
    width: 16px !important;
  }
}
</style>
