<template>
  <div v-if="personioFieldsLoaded" class="step-filter">
    <v-form
      ref="personioFieldsForm"
      v-model="isFormValid"
      class="multi-col-validation"
      @submit.prevent="submitStep(false)"
    >
      <v-data-table
        :items="loadedFields"
        :headers="headers"
        :loading="loading"
        class="text-no-wrap v-data-table--personio-filters mb-4 v-row"
        hide-default-footer
        disable-pagination
      >
        <template #[`item.field`]="{ item, index }" class="px-0">
          <span class="d-flex align-center">
            <v-autocomplete
              v-model="item.field"
              :items="fieldsOptions"
              single-line
              outlined
              dense
              label="Please select"
              placeholder="Please select"
              class="py-6"
              hide-details="auto"
              :error="item.notFound"
              :disabled="loading"
              @change="
                item.value = '';
                item.notFound = false;
              "
            ></v-autocomplete>
          </span>
        </template>

        <template #[`item.operation`]="{ item, index }">
          <span class="d-flex align-start">
            <v-select
              v-model="item.operation"
              :items="getFieldOperators(item.field) || []"
              outlined
              dense
              label="Please select"
              placeholder="Please select"
              class="py-6"
              hide-details="auto"
              :error="item.notFound"
              :disabled="loading || !item.field"
              @change="item.value = ''"
            ></v-select>
          </span>
        </template>

        <template #[`item.value`]="{ item, index }">
          <span class="d-flex align-start">
            <v-autocomplete
              v-if="getFieldValues(item.field).length > 0 && getFieldType(item) !== 'date'"
              v-model="item.value"
              :items="getFieldValues(item.field)"
              single-line
              outlined
              dense
              label="Please select"
              placeholder="Please select"
              class="py-6"
              hide-details="auto"
              :disabled="!item.operation || loading"
              clearable
              multiple
              @change="updatePreview"
            ></v-autocomplete>
            <v-text-field
              v-if="getFieldValues(item.field).length === 0 && getFieldType(item) !== 'date'"
              v-model="item.value"
              outlined
              single-line
              dense
              :type="getFieldType(item) === 'integer' ? 'number' : 'text'"
              label="Please select"
              hide-details="auto"
              :disabled="!item.operation || loading"
              clearable
              class="py-6"
              @change="updatePreview"
            ></v-text-field>
            <v-menu
              v-if="getFieldType(item) === 'date'"
              ref="menuref"
              v-model="item.menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  id="startdate"
                  v-model="item.startDateFormatted"
                  placeholder="Please select"
                  outlined
                  class="py-6"
                  dense
                  :prepend-inner-icon="icons.mdiCalendar"
                  :disabled="!item.operation"
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                ref="picker"
                v-model="item.value"
                min="1950-01-01"
                color="primary"
                :locale="currentLang === 'en' ? 'US-en' : 'De-de'"
                @change="save(index), updatePreview"
              ></v-date-picker>
            </v-menu>
          </span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item, index }">
          <div v-if="item.field" class="py-6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  x-small
                  style="margin-top: 6px"
                  v-bind="attrs"
                  @click="onDelete(index)"
                  v-on="on"
                >
                  <v-icon size="22">
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete row</span>
            </v-tooltip>
          </div>
        </template>

        <template slot="no-data">
          <div class="my-5">
            <p>{{ $t('myBavNoResults') }}</p>
          </div>
        </template>
      </v-data-table>
    </v-form>
    <div class="d-flex mb-10">
      <a
        class="ml-3 d-flex align-center text-decoration-underline"
        role="button"
        :disabled="(loadedFields.length == 1 && loadedFields[0].value == null) || loading"
        @click.prevent="addFilterSelection"
      >
        <v-icon
          size="22"
          color="primary"
          class="mr-1"
          :disabled="(loadedFields.length == 1 && loadedFields[0].value == null) || loading"
        >
          {{ icons.mdiPlusCircle }} </v-icon
        ><span>Add AND-condition</span>
      </a>
      <a
        class="ml-3 d-flex align-center text-decoration-underline"
        role="button"
        :disabled="(loadedFields.length == 1 && loadedFields[0].value == null) || loading"
        @click.prevent="deleteFieldModal = true"
      >
        <v-icon
          size="22"
          color="primary"
          class="mr-1"
          :disabled="(loadedFields.length == 1 && loadedFields[0].value == null) || loading"
        >
          {{ icons.mdiDelete }}
        </v-icon>
        <span>Remove all conditions</span>
      </a>
    </div>
    <div>
      <v-expansion-panels :value="loading" :disabled="loading">
        <v-expansion-panel v-for="item in 1" :key="item">
          <v-expansion-panel-header class="d-flex justify-start">
            Preview
            <v-progress-circular
              v-if="loading"
              :size="18"
              :width="2"
              color="purple"
              indeterminate
              class="flex-grow-0 flex-shrink-0 ml-2"
            ></v-progress-circular>
            <span v-else class="ml-1">
              ({{ syncReport.numberOfFilteredResults }}/{{ syncReport.numberOfAllData }})</span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <personio-preview-data-table
              v-if="!loading"
              :companySelected="companySelected"
              :syncReport="syncReport"
              :loading="loading"
              @getPreviewFile="getPreviewFile"
            >
            </personio-preview-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div v-if="!isEdit" class="d-flex mt-10">
      <v-btn color="primary" outlined depressed class="flex-button personio-step__auth-btn" @click="$emit('stepBack')">
        Back to value mapping
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="flex-button personio-step__auth-btn" type="submit" @click="submitStep(false)">
        Continue to synchronisation
      </v-btn>
    </div>
    <div class="d-none">
      <ModalWindow :is-open="deleteFieldModal" warning>
        <template #content>
          <p class="text-base">Are you sure that you want to remove all conditions?</p>
        </template>
        <template #actions>
          <v-btn outlined depressed color="primary" @click="deleteFieldModal = false"> Cancel </v-btn>
          <v-btn color="primary" depressed @click="removeAll"> Yes </v-btn>
        </template>
      </ModalWindow>
      <ModalWindow :is-open="addedFieldModal" warning>
        <template #content>
          <p class="text-base">Are you sure that you want to continue without finishing filtering?</p>
        </template>
        <template #actions>
          <v-btn outlined depressed color="primary" @click="addedFieldModal = false"> Cancel </v-btn>
          <v-btn color="primary" depressed @click="$emit('next'), (addedFieldModal = false)"> Yes </v-btn>
        </template>
      </ModalWindow>
    </div>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, onMounted, watch, toRefs } from '@vue/composition-api';
import { mdiDelete, mdiCheckCircle, mdiPlusCircle, mdiMinusCircle, mdiCalendar } from '@mdi/js';
import { required } from '@core/utils/validation';
import moment from 'moment';
import fileDownload from 'js-file-download';
import ModalWindow from '@/components/modal/ModalWindow';
import {
  getPersonioCompanyById,
  getPersonioFields,
  putUpdatePersonio,
  signPersonioActivity,
  postPersonioSyncDry,
  getPreviewSyncReport,
} from '@/api/personio-connector';
import PersonioPreviewDataTable from '@/components/table/PersonioPreviewDataTable.vue';

import { ADMIN_ACTIVITY_TYPE } from '@/constants';

export default {
  components: { ModalWindow, PersonioPreviewDataTable },
  props: {
    companySelected: {
      required: true,
    },
    isEdit: {
      type: Boolean,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;
    const { companySelected } = toRefs(props);

    const operatorsDictionary = {
      eq: 'IS NOT',
      ne: 'IS',
      lt: 'IS GREATER THAN OR EQUAL TO',
      gt: 'IS SMALLER THAN OR EQUAL TO',
      lteq: 'IS GREATER THAN',
      gteq: 'IS SMALLER THAN',
      bf: 'AFTER',
      af: 'BEFORE',
    };

    const personioFields = ref([]);
    const fieldsOptions = ref([]);
    const existingConfig = ref(null);
    const menu = ref(false);
    const picker = ref(null);
    const menuref = ref(null);
    const selectedFields = ref(null);
    const deleteFieldModal = ref(false);
    const addedFieldModal = ref(false);
    const hiddenInput = ref(null);
    const personioFieldsForm = ref(null);
    const isFormValid = ref(null);
    const loading = ref(false);
    const syncReport = ref([]);
    const isEditingSetup = ref(false);
    const personioFieldsLoaded = ref(false);

    const loadedFields = ref([
      {
        field: '',
        operation: '',
        value: '',
      },
    ]);
    const loadedFieldsCopy = ref([]);
    const getChangesStatus = computed(() => {
      return JSON.stringify(loadedFields.value) !== JSON.stringify(loadedFieldsCopy.value);
    });
    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const canContinue = computed(() => {
      const checkForUnfinishedfilters = loadedFields.value.find((e) => e.field && (!e.operation || !e.value));
      if (checkForUnfinishedfilters) {
        vm.$emit('validationStatusChange', false);
        return false;
      }
      vm.$emit('validationStatusChange', true);
      return true;
    });
    const lastDryRunId = ref(null);
    const nextSyncPreview = ref(null);
    const personioCompany = ref(null);

    const updatePreview = () => {
      const filteredUnfinishedFilters = loadedFields.value.filter((e) => e.field && e.operation && e.value);

      const payload = {
        portalCompanyId: companySelected.value.id,
        fieldEnumMapping: existingConfig.value.fieldEnumMapping,
        targetToSourceFieldMapping: existingConfig.value.targetToSourceFieldMapping,
        filters: filteredUnfinishedFilters.map((f) => {
          return f.operation === 'ne' && Array.isArray(f.value)
            ? { field: f.field, operation: 'neall', value: f.value }
            : { field: f.field, operation: f.operation, value: f.value };
        }),
      };

      putUpdatePersonio(companySelected.value.id, payload).then(() => {
        signActivity();
        dryRun();
      });
    };

    const dryRun = () => {
      loading.value = true;
      postPersonioSyncDry(companySelected.value.id).then((res) => {
        syncReport.value = res.data;
        lastDryRunId.value = res.data.runId;
        nextSyncPreview.value = res.data.log.match(/\d+/g);
        loading.value = false;
      });
    };

    const fetchCompany = () => {
      loading.value = true;
      getPersonioCompanyById(companySelected.value.id).then((res) => {
        personioCompany.value = res.data;
      });
      dryRun();
    };

    const getPreviewFile = () => {
      getPreviewSyncReport(lastDryRunId.value).then((res) => {
        fileDownload(res.data, `${personioCompany.value.name}_preview_sync_${moment().format('DD.MM.YYYY')}.xls`);
      });
    };

    const getFields = () => {
      getPersonioFields(companySelected.value.id).then((res) => {
        personioFieldsLoaded.value = true;
        if (!res.data.fields || !res.data.fields.length) return [];

        personioFields.value = res.data.fields;
        fieldsOptions.value = res.data.fields.map((item) => item.name);
      });
    };

    const getFieldOperators = (fieldName) => {
      if (!fieldName) return [];
      const personioField = personioFields.value.find((item) => item.name === fieldName);
      if (personioField && personioField.operators && personioField.operators.length > 0) {
        return personioField.operators.map((item) => {
          if (operatorsDictionary[item]) {
            return { text: operatorsDictionary[item] || '?', value: item };
          }
        });
      }
      return [];
    };

    const getFieldValues = (fieldName) => {
      if (!fieldName || !personioFields.value.length) return [];
      const personioField = personioFields.value.find((item) => item.name === fieldName);
      if (personioField && personioField.values && personioField.values.length > 0) {
        return personioField.values.map((item) => item || 'Not specified (Empty)');
      }
      return [];
    };

    const getFieldType = (item) => {
      if (!item.field) return '';

      const personioField = personioFields.value.find((i) => i.name === item.field);
      if (item.field && !personioField) {
        vm.$store.commit('showMessage', {
          text: `Error: Unrecognized personio field: ${item.field}`,
          color: 'error',
          timeout: '10000',
        });

        item.notFound = true;

        return '';
      }
      return personioField.type;
    };

    const fetchExistingFilters = () => {
      getPersonioCompanyById(companySelected.value.id).then((res) => {
        loadedFields.value = res.data.configJson.filterConfig.map((f) => {
          return f.operation === 'neall'
            ? { ...f, operation: 'ne' }
            : { field: f.field, operation: f.operation, value: f.value };
        });
        loadedFieldsCopy.value = _.cloneDeep(loadedFields.value);
        existingConfig.value = res.data.configJson;
        if (loadedFields.value.length === 0) addFilterSelection();
      });
    };

    const addFilterSelection = () => {
      loadedFields.value.push({
        field: '',
        operation: '',
        value: '',
      });
    };

    const signActivity = () => {
      const userName =
        userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : `${userData.id}`;
      const msg = `${userName} filtered results successful`;
      signPersonioActivity(companySelected.value.id, userData.id, { msg }, ADMIN_ACTIVITY_TYPE.INFO);
    };

    const submitStep = (isDiscard = false) => {
      return new Promise((resolve, reject) => {
        const filteredUnfinishedFilters = isDiscard
          ? loadedFieldsCopy.value
          : loadedFields.value.filter((e) => e.field && e.operation && e.value);

        const formattedFiltersPayload = filteredUnfinishedFilters.map((f) => {
          return f.operation === 'ne' && Array.isArray(f.value)
            ? { field: f.field, operation: 'neall', value: f.value }
            : { field: f.field, operation: f.operation, value: f.value };
        });

        if (filteredUnfinishedFilters.length > 0) {
          const payload = {
            portalCompanyId: companySelected.value.id,
            fieldEnumMapping: existingConfig.value.fieldEnumMapping,
            targetToSourceFieldMapping: existingConfig.value.targetToSourceFieldMapping,
            filters: formattedFiltersPayload,
            step: 4,
          };

          putUpdatePersonio(companySelected.value.id, payload)
            .then(() => {
              signActivity();
              isEditingSetup.value = false;
              loadedFieldsCopy.value = _.cloneDeep(formattedFiltersPayload);
              loadedFields.value = _.cloneDeep(formattedFiltersPayload);
              preventAccidentExit();
              resolve();
            })
            .catch(() => reject());
        } else {
          reject();
          preventAccidentExit();
        }
      });
    };

    const preventAccidentExit = () => {
      const checkForUnfinishedfilters = loadedFields.value.find((e) => e.field && (!e.operation || !e.value));

      if (checkForUnfinishedfilters) {
        addedFieldModal.value = true;
      } else {
        vm.$emit('next');
      }
    };

    const onDelete = (index) => {
      if (loadedFields.value.length == 1) {
        removeAll();
      } else {
        selectedFields.value = index;
        loadedFields.value.splice(selectedFields.value, 1);
      }
      updatePreview();
    };

    const confirmDelete = () => {
      loadedFields.value.splice(selectedFields.value, 1);
      deleteFieldModal.value = false;
      updatePreview();
    };
    const removeAll = () => {
      loadedFields.value = [
        {
          field: '',
          operation: '',
          value: '',
        },
      ];
      deleteFieldModal.value = false;
      updatePreview();
    };
    const reset = () => {
      isEditingSetup.value = false;
      // Should reset fields that exist in this step
      return true;
    };
    const formatStartDate = (index) => {
      loadedFields.value[index].startDateFormatted = moment(loadedFields.value[index].value).format('DD.MM.YYYY');
    };
    const clearValue = (index) => {
      loadedFields.value[index].value = null;
      loadedFields.value[index].startdate = null;
      loadedFields.value[index].startDateFormatted = null;
    };
    const save = (index, d) => {
      menuref.value.save(d);
      formatStartDate(index);
    };
    const headers = computed(() => {
      return [
        {
          text: 'IDENTIFIER',
          class: 'text-uppercase',
          value: 'field',
          width: '33%',
          sortable: false,
        },
        {
          text: 'RELATIVE',
          class: 'text-uppercase',
          value: 'operation',
          width: '33%',
          sortable: false,
        },
        {
          text: 'VALUE(S)',
          class: 'text-uppercase',
          value: 'value',
          width: '33%',
          sortable: false,
        },
        {
          text: '',
          class: 'text-uppercase',
          value: 'actions',
          sortable: false,
        },
      ];
    });

    onMounted(() => {
      getFields();
      fetchExistingFilters();
      fetchCompany();
    });
    watch(
      () => loadedFields.value,
      (currentValue) => {
        if (loadedFieldsCopy.value.length) {
          const areEqual = JSON.stringify(currentValue) === JSON.stringify(loadedFieldsCopy.value);
          areEqual ? (isEditingSetup.value = false) : (isEditingSetup.value = true);
        } else {
          loadedFieldsCopy.value = _.cloneDeep(loadedFields.value);
        }
      },
      { deep: true },
    ),
      watch(canContinue, (v) => {
        vm.$emit('validationStatusChange', v);
      });

    return {
      personioFieldsLoaded,
      getChangesStatus,
      canContinue,
      personioFields,
      fieldsOptions,
      selectedFields,
      deleteFieldModal,
      hiddenInput,
      loadedFields,
      loading,
      headers,
      addFilterSelection,
      onDelete,
      confirmDelete,
      addedFieldModal,
      submitStep,
      personioFieldsForm,
      isFormValid,
      reset,
      getFieldOperators,
      getFieldValues,
      getFieldType,
      removeAll,
      picker,
      menu,
      save,
      menuref,
      currentLang,
      clearValue,
      lastDryRunId,
      nextSyncPreview,
      getPreviewFile,
      personioCompany,
      syncReport,
      updatePreview,
      isEditingSetup,
      loadedFieldsCopy,

      icons: {
        mdiDelete,
        mdiCheckCircle,
        mdiPlusCircle,
        mdiMinusCircle,
        mdiCalendar,
      },
      validators: {
        required,
      },
    };
  },
};
</script>
<style lang="scss">
.step-filter {
  .v-input.error--text,
  .v-input:not(.v-input--is-label-active) {
    + .v-icon {
      opacity: 0.5;
      color: rgba(16, 16, 17, 0.12) !important;
    }
  }
}
a {
  &[disabled] {
    pointer-events: none;
    color: rgba(16, 16, 17, 0.38) !important;
  }
}
.v-data-table {
  &--personio-filters {
    &.only-mandatory {
      tr th {
        &:last-child {
          display: none;
        }
      }
      tr td {
        &:nth-child(3) {
          padding-right: 16px !important;
        }
      }
    }
    tbody tr {
      vertical-align: top;
    }
    tr td {
      .v-input--is-readonly {
        pointer-events: none;
      }

      &:nth-child(4) {
        padding-left: 0 !important;
      }
    }
    .v-data-table-header {
      th {
        background: none !important;
        border-top: 0;
        border-bottom-color: #2830ce !important;

        span {
          color: #2830ce;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
