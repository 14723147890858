<template>
  <v-col cols="12" md="12" sm="12">
    <v-card class="personio-overview__card" style="min-height: 405px">
      <div class="personio-overview__card-title mb-4">Set filters ({{ filters.length }})</div>
      <v-simple-table no-data-text="0 filters selected" fixed-header height="300px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left personio-overview__th">
                <span class="text--blue">Identifier</span>
              </th>
              <th class="text-left personio-overview__th">
                <span class="text--blue">Relative</span>
              </th>
              <th class="text-left personio-overview__th">
                <span class="text--blue">Value(s)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filters" :key="index + 'filter'">
              <td class="personio-overview__td">{{ item.field }}</td>
              <td class="personio-overview__td">{{ operatorsDictionary[item.operation] }}</td>
              <td class="personio-overview__td">{{ Array.isArray(item.value) ? item.value.join(' ') : item.value }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="isEdit" class="personio-overview__edit" @click="$emit('changeConfigModal')">Edit</div>
    </v-card>
  </v-col>
</template>

<script>
import { toRefs, ref } from '@vue/composition-api';

export default {
  props: ['personioCompany', 'isEdit'],
  name: 'SelectedFilters',
  setup(props) {
    const filters = ref([]);
    const { personioCompany } = toRefs(props);

    const operatorsDictionary = {
      eq: 'IS NOT',
      ne: 'IS',
      lt: 'IS GREATER THAN OR EQUAL TO',
      gt: 'IS SMALLER THAN OR EQUAL TO',
      lteq: 'IS GREATER THAN',
      gteq: 'IS SMALLER THAN',
      bf: 'AFTER',
      af: 'BEFORE',
    };

    filters.value = personioCompany.value.configJson.filterConfig.map((f) => {
      return f.operation === 'neall'
        ? { ...f, operation: 'ne' }
        : { field: f.field, operation: f.operation, value: f.value };
    });

    return {
      filters,
      operatorsDictionary,
    };
  },
};
</script>
<style scoped lang="scss">
@import '../../styles/pages/_dashboard.scss';
</style>
