<template>
  <div class="personio-overview">
    <v-row v-if="personioCompany" class="mb-6">
      <v-col cols="12" md="10" class="mx-auto">
        <a href="#" class="personio-connect__back" @click.prevent="$router.push('/personio-companies')">
          <v-icon color="primary">{{ icons.mdiChevronDoubleLeft }}</v-icon>
          <span class="ml-2">Back to overview of personio companies</span>
        </a>
      </v-col>
    </v-row>
    <div>
      <div class="row">
        <div class="mx-auto col-sm-12 col-md-10 col-lg-10 col-12">
          <v-card class="v-card--no-border v-card--no-bg mb-10">
            <h1 class="font-weight-bold text-center">
              {{ personioCompany.name }}
            </h1>
          </v-card>
        </div>
      </div>
      <div class="row mt-0">
        <div class="mx-auto col-sm-12 col-md-10 col-lg-11 col-12">
          <v-row class="row">
            <last-sync
              :personioCompany="personioCompany"
              :lastSyncTimestamp="lastSyncTimestamp"
              :lastRunReport="lastRunReport"
              :lastRunId="lastRunId"
            />
            <next-sync
              :personioCompany="personioCompany"
              :nextSyncPreview="nextSyncPreview"
              :lastDryRunId="lastDryRunId"
            />
          </v-row>
          <v-row class="row">
            <activity-timeline :syncHistory="syncHistory" />
            <v-col cols="12" md="6" sm="12">
              <scheduled-syncs
                :currentSyncSchedule="personioCompany.schedule"
                :autosync="personioCompany.autosync"
                :personioCompanyId="personioCompany.portalCompanyId"
                :lastSyncTimestamp="lastSyncTimestamp"
                @recordAction="recordAction"
              />
              <personio-connect :personioCompany="personioCompany" @recordAction="recordAction" />
            </v-col>
          </v-row>
          <v-row class="row">
            <selected-filters
              :key="rerenderKey + 'filtersConfig'"
              :personioCompany="personioCompany"
              :isEdit="isEdit"
              @changeConfigModal="changeFiltersModal = true"
            />
          </v-row>
          <v-row class="row">
            <mapped-fields
              :key="rerenderKey + 'fields'"
              :personioCompany="personioCompany"
              :isEdit="isEdit"
              @changeConfigModal="changeFieldsModal = true"
            />
            <mapped-values
              :key="rerenderKey + 'values'"
              :personioCompany="personioCompany"
              :isEdit="isEdit"
              @newFieldAdded="handleWarning"
              @changeConfigModal="changeValuesModal = true"
            />
          </v-row>
        </div>
      </div>
      <ModalWindow
        :key="rerenderKey + 'edit-fields-modal'"
        :is-open="changeFieldsModal"
        title="Edit field mapping"
        hasForm
        is-full-screen
        isFullScreenFixed
      >
        <template #actionsFullScreenClose>
          <v-btn icon dark color="white" @click="closeEditFieldsModal">
            <v-icon size="20" class="d-sm-none">{{ icons.mdiClose }}</v-icon>
            <v-icon class="d-none d-sm-block">{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </template>
        <template #actionsFullScreenSave>
          <v-btn
            dark
            text
            :disabled="!isNewFieldsValid"
            :loading="savingConfig"
            class="my-auto"
            type="submit"
            @click="saveFieldChanges"
          >
            <span class="save-change-config" :class="{ active: isNewFieldsValid }">SAVE CHANGES</span>
          </v-btn>
        </template>
        <v-card> </v-card>

        <template #content>
          <div v-if="personioCompany" class="change-config">
            <div class="change-config__tabs">
              <div class="change-config__tab">
                <edit-fields
                  ref="editFields"
                  is-edit
                  :isLoading="savingConfig"
                  :companySelected="personioCompany"
                  @validationStatusChange="isNewFieldsValid = $event"
                />
              </div>
            </div>
          </div>
        </template>
      </ModalWindow>
      <ModalWindow
        :key="rerenderKey + 'edit-values-modal'"
        :is-open="changeValuesModal"
        title="Edit value mapping"
        hasForm
        is-full-screen
        isFullScreenFixed
      >
        <template #actionsFullScreenClose>
          <v-btn icon dark color="white" @click="closeEditValuesModal">
            <v-icon size="20" class="d-sm-none">{{ icons.mdiClose }}</v-icon>
            <v-icon class="d-none d-sm-block">{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </template>
        <template #actionsFullScreenSave>
          <v-btn
            dark
            text
            :loading="savingConfig"
            :disabled="!isNewValuesValid"
            class="my-auto"
            type="submit"
            @click="saveValueChanges"
          >
            <span class="save-change-config" :class="{ active: isNewValuesValid }">SAVE CHANGES</span>
          </v-btn>
        </template>
        <v-card> </v-card>

        <template #content>
          <div v-if="personioCompany" class="change-config">
            <div v-if="warningMsg" class="change-config__warning">{{ warningMsg }}</div>
            <div class="change-config__tabs">
              <div class="change-config__tab">
                <edit-values
                  ref="editValues"
                  :key="rerenderKey + 'edit-values'"
                  is-edit
                  :companySelected="personioCompany"
                  @validationStatusChange="isNewValuesValid = $event"
                />
              </div>
            </div>
          </div>
        </template>
      </ModalWindow>
      <ModalWindow
        :key="rerenderKey + 'edit-filters-modal'"
        :is-open="changeFiltersModal"
        title="Edit filter selection"
        hasForm
        is-edit
        is-full-screen
        isFullScreenFixed
      >
        <template #actionsFullScreenClose>
          <v-btn icon dark color="white" @click="closeEditFiltersModal">
            <v-icon size="20" class="d-sm-none">{{ icons.mdiClose }}</v-icon>
            <v-icon class="d-none d-sm-block">{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </template>
        <template #actionsFullScreenSave>
          <v-btn
            dark
            text
            :disabled="!isNewFiltersValid"
            :loading="savingConfig"
            class="my-auto"
            type="submit"
            @click="saveFilterChanges"
          >
            <span class="save-change-config" :class="{ active: isNewFiltersValid }">SAVE CHANGES</span>
          </v-btn>
        </template>
        <v-card> </v-card>

        <template #content>
          <div v-if="personioCompany" class="change-config">
            <div class="change-config__tabs">
              <div class="change-config__tab">
                <edit-filters
                  ref="editFilters"
                  :key="rerenderKey + 'edit-filters'"
                  is-edit
                  :companySelected="personioCompany"
                  @validationStatusChange="isNewFiltersValid = $event"
                />
              </div>
            </div>
          </div>
        </template>
      </ModalWindow>
      <ModalWindow :is-open="confirmDiscardFiltersModal" warning>
        <template #content>
          <p class="text-base">Are you sure that you want to cancel without saving filtering?</p>
        </template>
        <template #actions>
          <v-btn outlined depressed color="primary" @click="confirmDiscardFiltersModal = false"> No, continue </v-btn>
          <v-btn color="primary" depressed @click="discardFilterChanges"> Yes, cancel </v-btn>
        </template>
      </ModalWindow>
    </div>
  </div>
</template>

<script>
import { mdiChevronDoubleLeft, mdiClose } from '@mdi/js';
import { ref, getCurrentInstance, computed, watch, onMounted } from '@vue/composition-api';
import moment from 'moment';
import fileDownload from 'js-file-download';
import {
  getPersonioCompanyById,
  postPersonioSyncDry,
  getPreviewSyncReport,
  getPersonioActivityTimeline,
  signPersonioActivity,
} from '@/api/personio-connector';
import EditFields from './personio-connect-tabs/step-field/PersonioConnectStepField';
import EditValues from './personio-connect-tabs/step-value/PersonioConnectStepValue';
import EditFilters from './personio-connect-tabs/step-filter/PersonioConnectStepFilter';
import PersonioConnect from '@/components/personio-dashboard/PersonioConnect.vue';
import ScheduledSyncs from '@/components/personio-dashboard/ScheduledSyncs.vue';
import MappedFields from '@/components/personio-dashboard/MappedFields.vue';
import MappedValues from '@/components/personio-dashboard/MappedValues.vue';
import SelectedFilters from '@/components/personio-dashboard/SelectedFilters.vue';
import ActivityTimeline from '@/components/personio-dashboard/ActivityTimeline.vue';
import LastSync from '@/components/personio-dashboard/LastSync.vue';
import NextSync from '@/components/personio-dashboard/NextSync.vue';
import ModalWindow from '@/components/modal/ModalWindow';
import { ADMIN_ACTIVITY_TYPE } from '@/constants';

export default {
  components: {
    MappedFields,
    MappedValues,
    SelectedFilters,
    ActivityTimeline,
    LastSync,
    NextSync,
    PersonioConnect,
    ScheduledSyncs,
    ModalWindow,
    EditFields,
    EditValues,
    EditFilters,
  },
  name: 'GlobalAdminPersonioDashboard',
  setup() {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;
    const rerenderKey = ref(0);

    const warningMsg = ref('');
    const savingConfig = ref(false);
    const changeFieldsModal = ref(false);
    const changeValuesModal = ref(false);
    const changeFiltersModal = ref(false);
    const confirmDiscardFiltersModal = ref(false);

    const isEdit = ref(vm.$route.query.edit);
    const isNewFiltersValid = ref(true);
    const isNewFieldsValid = ref(false);
    const isNewValuesValid = ref(false);
    const lastDryRunId = ref(null);
    const lastRunId = ref();
    const lastRunReport = ref(null);
    const nextSyncPreview = ref(null);
    const personioCompany = ref(null);
    const isFormValid = ref(false);
    const isPersonioIdVisible = ref(false);
    const isPersonioSecretVisible = ref(false);
    const personioCredsForm = ref(null);
    const personioId = ref('');
    const personioSecret = ref('');
    const lastSyncTimestamp = ref(null);
    const syncHistory = ref([]);

    const fetchTimeline = () => {
      getPersonioActivityTimeline(vm.$route.params.id).then((res) => {
        syncHistory.value = res.data.page;
      });
    };

    const recordAction = (action) => {
      signPersonioActivity(vm.$route.params.id, userData.id, action.detail, action.type).then(() => {
        fetchTimeline();
      });
    };

    const handleWarning = (msg) => {
      warningMsg.value = msg;
    };

    const fetchCompany = () => {
      dryRun();
      fetchTimeline();
      getPersonioCompanyById(vm.$route.params.id).then((res) => {
        personioCompany.value = res.data;
        personioCompany.value.id = res.data.portalCompanyId;
        rerenderKey.value += 1;

        if (res.data.lastRunId) {
          lastRunId.value = res.data.lastRunId;
          lastRunReport.value = res.data.lastRunDetail.match(/\d+/g);
          lastSyncTimestamp.value = moment(personioCompany.value.lastRunDate).format('DD.MM.YY, HH:mm');
        }
      });
    };

    const isChildFormValid = (ref) => {
      if (vm.$refs[ref]) {
        return vm.$refs[ref].canContinue;
      }
      return false;
    };

    const saveFieldChanges = () => {
      savingConfig.value = true;
      vm.$refs.editFields
        .submitStep()
        .then(() => {
          fetchCompany();
          savingConfig.value = false;
          closeEditFieldsModal();
        })
        .catch(() => (savingConfig.value = false));
    };

    const saveValueChanges = () => {
      savingConfig.value = true;
      vm.$refs.editValues
        .submitStep()
        .then(() => {
          handleWarning('');
          fetchCompany();
          savingConfig.value = false;
          closeEditValuesModal();
        })
        .catch(() => (savingConfig.value = false));
    };

    const saveFilterChanges = () => {
      savingConfig.value = true;
      vm.$refs.editFilters
        .submitStep()
        .then(() => {
          fetchCompany();
          savingConfig.value = false;
          closeEditFiltersModal();
        })
        .catch(() => (savingConfig.value = false));
    };

    const discardFilterChanges = () => {
      savingConfig.value = true;
      vm.$refs.editFilters
        .submitStep(true)
        .then(() => {
          fetchCompany();
          savingConfig.value = false;
          confirmDiscardFiltersModal.value = false;
          vm.$store.commit('app/TOGGLE_CONTENT_OVERLAY', false);
          changeFiltersModal.value = false;
          rerenderKey.value += 1;
        })
        .catch(() => (savingConfig.value = false));
    };

    const dryRun = () => {
      postPersonioSyncDry(vm.$route.params.id).then((res) => {
        lastDryRunId.value = res.data.runId;
        nextSyncPreview.value = res.data.log.match(/\d+/g);
      });
    };

    const closeEditFieldsModal = () => {
      vm.$store.commit('app/TOGGLE_CONTENT_OVERLAY', false);
      changeFieldsModal.value = false;
      rerenderKey.value += 1;
    };

    const closeEditValuesModal = () => {
      vm.$store.commit('app/TOGGLE_CONTENT_OVERLAY', false);
      changeValuesModal.value = false;
      rerenderKey.value += 1;
    };

    const closeEditFiltersModal = () => {
      const areEqual = !vm.$refs.editFilters.getChangesStatus;
      if (areEqual) {
        vm.$store.commit('app/TOGGLE_CONTENT_OVERLAY', false);
        changeFiltersModal.value = false;
        rerenderKey.value += 1;
      } else {
        confirmDiscardFiltersModal.value = true;
      }
    };

    fetchCompany();

    return {
      discardFilterChanges,
      confirmDiscardFiltersModal,
      isNewFiltersValid,
      isNewFieldsValid,
      isNewValuesValid,
      isChildFormValid,
      warningMsg,
      handleWarning,
      saveFieldChanges,
      saveValueChanges,
      saveFilterChanges,
      changeFieldsModal,
      changeValuesModal,
      changeFiltersModal,
      closeEditFieldsModal,
      closeEditValuesModal,
      closeEditFiltersModal,
      rerenderKey,
      savingConfig,
      moment,
      recordAction,
      lastRunId,
      lastRunReport,
      nextSyncPreview,
      lastDryRunId,
      personioCredsForm,
      isFormValid,
      personioId,
      personioSecret,
      isPersonioSecretVisible,
      isPersonioIdVisible,
      personioCompany,
      syncHistory,
      lastSyncTimestamp,
      isEdit,
      ADMIN_ACTIVITY_TYPE,
      icons: {
        mdiChevronDoubleLeft,
        mdiClose,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/pages/_dashboard.scss';

.personio-overview {
}
</style>
