<template>
  <div class="step-field">
    <v-form ref="personioFieldsForm" v-model="isFormValid" class="multi-col-validation">
      <v-data-table
        v-model="fieldsTable"
        :items="fieldsToMap"
        :headers="headers"
        class="text-no-wrap v-data-table--personio-fields mb-4"
        hide-default-footer
        disable-pagination
      >
        <template #[`item.degura_field`]="{ item }" class="px-0">
          <span class="d-flex align-start">
            <span class="mr-3 mt-8">Map: </span>

            <v-text-field
              v-if="PERSONIO_REQUIRED_FIELDS.includes(item.key)"
              v-model="item.key"
              single-line
              dense
              outlined
              hide-details="auto"
              :label="item.key"
              :placeholder="item.key"
              class="py-6"
              item-value="degura_field"
              readonly
              @change="isEditingSetup = true"
            ></v-text-field>

            <v-autocomplete
              v-else
              v-model="item.key"
              :readonly="isLoading"
              :items="filteredItemsDegura"
              single-line
              outlined
              dense
              hide-selected
              clearable
              label="Please select"
              placeholder="Please select"
              class="py-6"
              hide-details="auto"
              :rules="[validators.required]"
              @change="isEditingSetup = true"
            ></v-autocomplete>
          </span>
        </template>

        <template #[`item.company_field`]="{ item }">
          <span class="d-flex align-start"
            ><span class="mr-3 mt-8">To: </span>
            <v-autocomplete
              v-model="item.value"
              :readonly="isLoading"
              :items="filteredItemsCompany"
              single-line
              outlined
              dense
              label="Please select"
              placeholder="Please select"
              class="py-6"
              hide-details="auto"
              clearable
              :rules="[validators.required]"
              @change="isEditingSetup = true"
            ></v-autocomplete>

            <v-icon size="22" color="success" class="ml-3 mt-8">
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item, index }">
          <div v-if="!PERSONIO_REQUIRED_FIELDS.includes(item.key)" class="py-6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  :disabled="isLoading"
                  x-small
                  style="margin-top: 6px; margin-left: 4px"
                  v-bind="attrs"
                  @click="onDelete(index)"
                  v-on="on"
                >
                  <v-icon size="22">
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete row</span>
            </v-tooltip>
          </div>
        </template>

        <template slot="no-data">
          <div class="my-5">
            <p>{{ $t('myBavNoResults') }}</p>
          </div>
        </template>
      </v-data-table>
    </v-form>
    <div class="d-flex">
      <a
        v-if="!isLoading"
        class="ml-14 d-flex align-center text-decoration-underline"
        role="button"
        @click.prevent="addFieldMapping"
      >
        <v-icon size="22" color="primary" class="mr-1"> {{ icons.mdiPlusCircle }} </v-icon><span>Add field</span>
      </a>
    </div>

    <div class="d-flex mt-10 mb-1">
      <v-btn
        v-if="!isEdit"
        color="primary"
        outlined
        depressed
        class="flex-button personio-step__auth-btn"
        @click="$emit('stepBack')"
      >
        Back to credentials
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!isEdit"
        color="primary"
        :disabled="submitDisabled"
        class="flex-button personio-step__auth-btn"
        type="submit"
        @click="submitStep"
      >
        Continue to value mapping
      </v-btn>
    </div>
    <ModalWindow :is-open="deleteFieldModal" warning>
      <template #content>
        <p class="text-base">
          Are you sure that you want to remove this field? All mapping, filter and sync settings are then reset!
        </p>
      </template>
      <template #actions>
        <v-btn outlined depressed color="primary" @click="deleteFieldModal = false"> Cancel </v-btn>
        <v-btn color="primary" depressed @click="confirmDelete"> Yes </v-btn>
      </template>
    </ModalWindow>
    <ModalWindow :is-open="addedFieldModal" warning>
      <template #content>
        <p class="text-base">
          You added a field afterwards, please check all the following steps if you need to make adjustments.
        </p>
      </template>
      <template #actions>
        <v-btn outlined depressed color="primary" @click="addedFieldModal = false"> Cancel </v-btn>
        <v-btn color="primary" depressed @click="$emit('next'), (addedFieldModal = false)"> Yes </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import { ref, computed, watch, getCurrentInstance, onMounted, toRefs } from '@vue/composition-api';
import { mdiDelete, mdiCheckCircle, mdiPlusCircle } from '@mdi/js';
import { required } from '@core/utils/validation';
import _ from 'lodash';
import ModalWindow from '@/components/modal/ModalWindow';
import {
  getPortalAttributes,
  getPersonioAttributes,
  getPersonioCompanyById,
  putUpdatePersonio,
  signPersonioActivity,
} from '@/api/personio-connector';
import { ADMIN_ACTIVITY_TYPE, PERSONIO_REQUIRED_FIELDS } from '@/constants';

export default {
  components: { ModalWindow },
  props: {
    companySelected: {
      required: true,
    },
    isEdit: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;
    const { companySelected, isEdit } = toRefs(props);
    const existingConfig = ref(null);

    const fieldsTable = ref(null);
    const selectedFields = ref(null);
    const deleteFieldModal = ref(false);
    const addedFieldModal = ref(false);
    const personioFieldsForm = ref(null);
    const isFormValid = ref(null);
    const isEditingSetup = ref(false);

    const deguraFieldOptions = ref([]);
    const companyFieldOptions = ref([]);
    const fieldsToMap = ref([]);
    const fieldsToMapCopy = ref(null);

    const canContinue = computed(() => {
      const mappedFields = arrToObjKeys(fieldsToMap.value);
      if (Object.values(mappedFields).every((x) => !!x)) {
        vm.$emit('validationStatusChange', true);
      }
      return Object.values(mappedFields).every((x) => !!x);
    });

    const arrToObjKeys = (arr) => {
      return arr.reduce((o, item) => Object.assign(o, { [item.key]: item.value }), {});
    };

    const getDeguraFields = () => {
      getPortalAttributes().then((res) => {
        deguraFieldOptions.value = res.data.map((i) => ({
          text: i,
          value: i,
          disabled: false,
        }));
      });
    };

    const fetchPersonioCompany = () => {
      getPersonioCompanyById(companySelected.value.id).then((res) => {
        existingConfig.value = res.data.configJson;
        const existingEntries = Object.entries(res.data.configJson.targetToSourceFieldMapping);
        if (existingEntries.length > 0) {
          fieldsToMap.value = existingEntries.map((i) => {
            return {
              key: i[0],
              value: i[1],
            };
          });
        } else {
          fieldsToMap.value = [...PERSONIO_REQUIRED_FIELDS.map((i) => ({ key: i, value: '' }))];
        }

        getCompanyFields();
        fieldsToMapCopy.value = _.cloneDeep(fieldsToMap.value);
      });
    };

    const getCompanyFields = () => {
      getPersonioAttributes(companySelected.value.id).then((res) => {
        companyFieldOptions.value = res.data.attributes.map((i) => ({
          text: i.label,
          value: i.key,
          disabled: false,
        }));
      });
    };

    const filteredItemsDegura = computed(() => {
      return deguraFieldOptions.value.map((item) => {
        item.disabled = fieldsToMap.value.some((e) => e.key === item.value);
        return item;
      });
    });

    const filteredItemsCompany = computed(() => {
      return companyFieldOptions.value.map((item) => {
        item.disabled = fieldsToMap.value.some((e) => e.value === item.value);
        return item;
      });
    });

    const submitDisabled = computed(() => {
      return !Object.values(arrToObjKeys(fieldsToMap.value)).every((x) => !!x);
    });

    const addFieldMapping = () => {
      fieldsToMap.value.push({ key: '', value: '' });
    };

    const signActivity = () => {
      isEditingSetup.value = false;
      const userName =
        userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : `${userData.id}`;
      const msg = isEdit.value ? `${userName} changed field mapping` : `${userName} mapped fields successful`;
      const type = isEdit.value ? ADMIN_ACTIVITY_TYPE.WARNING : ADMIN_ACTIVITY_TYPE.INFO;
      signPersonioActivity(companySelected.value.id, userData.id, { msg }, type);
    };

    const submitStep = () => {
      return new Promise((resolve, reject) => {
        const isValid = personioFieldsForm.value.validate();
        if (!isValid) {
          reject();
          return;
        }

        const mappedFields = arrToObjKeys(fieldsToMap.value);
        if (!Object.values(mappedFields).every((x) => !!x)) {
          reject();
          return;
        }

        const payload = {
          portalCompanyId: companySelected.value.id,
          fieldEnumMapping: existingConfig.value.fieldEnumMapping,
          targetToSourceFieldMapping: mappedFields,
          filters: existingConfig.value.filterConfig,
          step: 2,
        };

        putUpdatePersonio(companySelected.value.id, payload)
          .then(() => {
            signActivity();
            vm.$emit('next');
            resolve();
          })
          .catch((e) => reject(e));
      });
    };

    const onDelete = (index) => {
      selectedFields.value = index;
      fieldsToMap.value.splice(selectedFields.value, 1);
      deleteFieldModal.value = false;
    };

    const confirmDelete = () => {
      fieldsToMap.value.splice(selectedFields.value, 1);
      deleteFieldModal.value = false;
    };

    const reset = () => {
      isEditingSetup.value = false;
      // Should reset fields that exist in this step
      return true;
    };

    const headers = computed(() => {
      return [
        {
          text: 'DEGURA FIELD',
          class: 'text-uppercase',
          value: 'degura_field',
          sortable: false,
        },
        {
          text: 'COMPANY FIELD',
          class: 'text-uppercase',
          value: 'company_field',
          sortable: false,
        },
        {
          text: '',
          class: 'text-uppercase',
          value: 'actions',
          sortable: false,
        },
      ];
    });

    onMounted(() => {
      getDeguraFields();
      fetchPersonioCompany();
    });

    watch(canContinue, (v) => {
      vm.$emit('validationStatusChange', v);
    });
    watch(
      () => fieldsToMap.value,
      (currentValue) => {
        const areEqual = JSON.stringify(currentValue) === JSON.stringify(fieldsToMapCopy.value);
        areEqual ? (isEditingSetup.value = false) : (isEditingSetup.value = true);
      },
      { deep: true },
    );
    return {
      canContinue,
      fieldsToMap,
      submitDisabled,
      PERSONIO_REQUIRED_FIELDS,
      fieldsTable,
      selectedFields,
      deleteFieldModal,
      headers,
      addFieldMapping,
      onDelete,
      confirmDelete,
      addedFieldModal,
      submitStep,
      personioFieldsForm,
      isFormValid,
      reset,
      filteredItemsDegura,
      filteredItemsCompany,
      arrToObjKeys,
      isEditingSetup,
      fieldsToMapCopy,

      icons: {
        mdiDelete,
        mdiCheckCircle,
        mdiPlusCircle,
      },
      validators: {
        required,
      },
    };
  },
};
</script>
<style lang="scss">
.step-field {
  .v-input.error--text,
  .v-input:not(.v-input--is-label-active) {
    + .v-icon {
      opacity: 0.5;
      color: rgba(16, 16, 17, 0.12) !important;
    }
  }
}
.v-data-table {
  &--personio-fields {
    &.only-mandatory {
      tr th {
        &:last-child {
          display: none;
        }
      }
      tr td {
        &:nth-child(2) {
          padding-right: 16px !important;
        }
      }
    }
    tbody tr {
      vertical-align: top;
    }
    tr td {
      .v-input--is-readonly {
        pointer-events: none;
      }
      &:nth-child(2) {
        padding-right: 0 !important;
      }
      &:nth-child(3) {
        padding: 0 !important;
      }
    }
    .v-data-table-header {
      th {
        background: none !important;
        border-top: 0;
        border-bottom-color: #2830ce !important;

        span {
          color: #2830ce;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
