<template>
  <v-col cols="12" md="6" sm="12">
    <v-card v-if="lastSyncTimestamp && lastRunReport.length > 0" class="personio-overview__card">
      <div class="personio-overview__card-title">Last Sync - {{ lastSyncTimestamp }}</div>
      <div class="d-flex mt-5 justify-space-between">
        <div class="personio-overview__card-tile">
          <div class="personio-overview__report-circle">
            <v-icon size="38" class="personio-overview__report-icon">
              {{ icons.mdiAccountPlus }}
            </v-icon>
          </div>
          <div class="personio-overview__report-number">{{ lastRunReport[0] }}</div>
        </div>
        <div class="personio-overview__card-tile">
          <div class="personio-overview__report-circle">
            <v-icon size="38" class="personio-overview__report-icon">
              {{ icons.mdiAccountEdit }}
            </v-icon>
          </div>
          <div class="personio-overview__report-number">{{ lastRunReport[1] }}</div>
        </div>
        <div class="personio-overview__card-tile">
          <div class="personio-overview__report-circle personio-overview__report-circle--warning">
            <v-icon size="38" class="personio-overview__report-icon">
              {{ icons.mdiAccountMinus }}
            </v-icon>
          </div>
          <div class="personio-overview__report-number">{{ lastRunReport[2] }}</div>
        </div>
      </div>
      <v-icon size="20" color="#2830CE" class="mt-5 cursor-pointer" @click="getReportFile(lastRunId)">{{
        icons.mdiDownload
      }}</v-icon>
    </v-card>
    <v-card v-else class="personio-overview__card">
      <div class="personio-overview__card-title">Last Sync - N/A</div>
      <div class="d-flex mt-5 justify-space-between">
        <div class="personio-overview__card-tile">
          <div class="personio-overview__report-circle">
            <v-icon size="38" class="personio-overview__report-icon">
              {{ icons.mdiAccountPlus }}
            </v-icon>
          </div>
          <div class="personio-overview__report-number">0</div>
        </div>
        <div class="personio-overview__card-tile">
          <div class="personio-overview__report-circle">
            <v-icon size="38" class="personio-overview__report-icon">
              {{ icons.mdiAccountEdit }}
            </v-icon>
          </div>
          <div class="personio-overview__report-number">0</div>
        </div>
        <div class="personio-overview__card-tile">
          <div class="personio-overview__report-circle personio-overview__report-circle--warning">
            <v-icon size="38" class="personio-overview__report-icon">
              {{ icons.mdiAccountMinus }}
            </v-icon>
          </div>
          <div class="personio-overview__report-number">0</div>
        </div>
      </div>
      <v-icon size="20" color="#2830CE" class="mt-5 cursor-pointer" disabled>{{ icons.mdiDownload }}</v-icon>
    </v-card>
  </v-col>
</template>

<script>
import fileDownload from 'js-file-download';
import moment from 'moment';
import { mdiAccountPlus, mdiAccountEdit, mdiAccountMinus, mdiDownload } from '@mdi/js';
import { toRefs } from '@vue/composition-api';
import { getPreviewSyncReport } from '@/api/personio-connector';

export default {
  name: 'LastSync',
  props: ['personioCompany', 'lastSyncTimestamp', 'lastRunReport', 'lastRunId'],
  setup(props) {
    const { personioCompany } = toRefs(props);

    const getReportFile = (id) => {
      const date = moment()
        .set({
          year: personioCompany.value.lastRunDate.year,
          month: personioCompany.value.lastRunDate.monthValue - 1,
          date: personioCompany.value.lastRunDate.dayOfMonth,
        })
        .format('DD.MM.YY');

      getPreviewSyncReport(id).then((res) => {
        fileDownload(res.data, `${personioCompany.value.name}_last_sync_${date}.xls`);
      });
    };

    return {
      getReportFile,
      icons: {
        mdiAccountPlus,
        mdiAccountEdit,
        mdiAccountMinus,
        mdiDownload,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/pages/_dashboard.scss';
</style>
