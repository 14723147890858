<template>
  <div class="personio mh-260 mt-3" :class="isEditing ? 'is-editing' : ''">
    <v-card class="personio-overview__card mh-260 front">
      <v-alert
        v-model="alert"
        close-text="Close Alert"
        :color="'#039181'"
        dark
        dismissible
        class="alert-personio pa-2"
        transition="slide-y-transition"
      >
        <div class="text-sm">You have successfully set up automatic synchronization!</div>
      </v-alert>
      <div class="personio-overview__card-content">
        <div class="personio-overview__card-title">Scheduled Syncs</div>
        <div class="personio-overview__card-schedule">
          <v-icon size="88" class="mb-2" :color="personioSchedule ? '#039181' : '#F98506'">{{ icons.mdiAlarm }}</v-icon>
          <div v-if="personioSchedule" class="personio-overview__card-subtitle text-center mb-2 mt-2">
            {{ personioSchedule }}
          </div>
          <div v-if="personioSchedule" class="personio-overview__card-subtitle text-center">
            Next auto sync:
            {{ autosync ? nextSyncTimestamp : 'None (disabled)' }}
          </div>
          <div v-if="!personioSchedule" class="personio-overview__card-subtitle text-center mb-2 mt-2">
            No automatic sync has been set yet
          </div>
          <div
            v-if="personioSchedule"
            class="personio-overview__edit"
            @click="(isEditing = !isEditing), (alert = false)"
          >
            Edit
          </div>
          <div v-if="!personioSchedule" class="personio-overview__edit" @click="isEditing = !isEditing">Set up</div>
        </div>
      </div>
      <div class="personio-overview__card-label">
        <div class="personio-overview__sync">
          <v-icon size="52" color="#898989" class="icon-db">
            {{ icons.mdiDatabase }}
          </v-icon>
          <v-icon
            size="16"
            color="#898989"
            class="icon-sync ml-8 mr-8"
            :class="{ 'animation-spin-back': isSyncRunning }"
          >
            {{ icons.mdiSync }}
          </v-icon>
          <v-icon size="52" color="#898989" class="icon-db">
            {{ icons.mdiDatabase }}
          </v-icon>
        </div>
        <v-btn class="mt-8 personio-overview__btn" :disabled="isSyncRunning" color="primary" @click="syncNow">
          Sync now
        </v-btn>
      </div>
    </v-card>
    <v-card class="personio-overview__card mh-260 back">
      <div class="personio-overview__card-label">
        <div class="personio-overview__sync">
          <v-icon size="105" :color="personioSchedule ? '#039181' : '#F98506'">{{ icons.mdiAlarm }}</v-icon>
        </div>
        <v-btn
          color="primary"
          :disabled="!time || !date || !repeat || !saveChangesAllowed"
          class="mt-8 personio-overview__btn"
          :class="{
            'schedule-card__btn--disabled': !time || !date || !repeat || !saveChangesAllowed,
          }"
          type="submit"
          @click="saveSchedule"
        >
          {{ personioSchedule ? 'Save changes' : 'Save setup' }}
        </v-btn>
      </div>
      <div class="personio-overview__card-content">
        <div v-if="personioSchedule" class="personio-overview__card-title">Update schedule</div>
        <div v-else class="personio-overview__card-title">Schedule Syncs</div>
        <div class="personio-overview__close">
          <v-btn
            icon
            color="primary"
            class="mr-5"
            @click="saveChangesAllowed ? (changeScheduledSyncsModal = true) : (isEditing = false)"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </div>
        <div class="personio-overview__card-schedule d-flex">
          <v-form ref="personioScheduleForm" class="personioScheduleForm" @submit.prevent>
            <v-select
              v-model="repeat"
              :items="repeatOptions"
              label="Repetition"
              placeholder="Repetition"
              outlined
              hide-details
              dense
              class="mb-5 mt-2"
              @change="(saveChangesAllowed = true), setRepeatOptions()"
            ></v-select>
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              class="datepicker-month"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  outlined
                  dense
                  :label="personioSchedule ? 'Time of interval' : 'Time of sync'"
                  readonly
                  v-bind="attrs"
                  hide-details
                  class="mb-5"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                v-model="time"
                format="24hr"
                color="primary"
                full-width
                hide-details
                class="mb-0"
                :min="minAllowedTime"
                @click:minute="$refs.menu.save(time)"
                @change="saveChangesAllowed = true"
              ></v-time-picker>
            </v-menu>
            <v-text-field
              :label="personioSchedule ? 'N/A' : 'N/A'"
              readonly
              outlined
              v-if="repeat === null"
              dense
              hide-details
              disabled
              value="Please choose a repetition"
              filled
              class="mb-5"
            ></v-text-field>
            <v-menu
              ref="menurefdate"
              v-model="menu1"
              v-if="repeat === 'ONCE' || (repeat === 'ONCE' && !personioSchedule)"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  :label="personioSchedule ? 'Date of interval' : 'Date of sync'"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                full-width
                :min="minAllowedDate"
                :max="maxAllowedDate"
                color="primary"
                :picker-date.sync="date"
                @click:date="$refs.menurefdate.save(date)"
                @change="(saveChangesAllowed = true), setRepeatOptions()"
              ></v-date-picker>
            </v-menu>
            <v-menu
              ref="menurefdatemonth"
              v-model="menu4"
              :close-on-content-click="false"
              :return-value.sync="date"
              v-if="repeat === 'MONTHLY' || (repeat === 'MONTHLY' && !personioSchedule)"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="personioSchedule ? 'Date of interval' : 'Date of sync'"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  hide-details
                  v-on="on"
                  :value="'on the ' + moment(dateMonth).format('Do')"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateMonth"
                full-width
                :min="minAllowedDate"
                :max="maxAllowedDate"
                color="primary"
                :class="repeat === 'MONTHLY' ? 'datepicker-month' : ''"
                :no-title="repeat === 'MONTHLY'"
                :show-current="false"
                @click:date="$refs.menurefdatemonth.save(date)"
                @change="(saveChangesAllowed = true), setRepeatOptions()"
              ></v-date-picker>
            </v-menu>

            <v-menu
              ref="menurefdatemonthbi"
              v-model="menu4"
              :close-on-content-click="false"
              :return-value.sync="date"
              v-if="repeat === 'TWICEMONTHLY' || (repeat === 'TWICEMONTHLY' && !personioSchedule)"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="personioSchedule ? 'Dates of interval' : 'Dates of sync'"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  hide-details
                  :value="
                    'on the ' + moment(dateTwiceMonth[0]).format('Do') + '/' + moment(dateTwiceMonth[1]).format('Do')
                  "
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="dateTwiceMonth"
                full-width
                :min="minAllowedDate"
                :max="maxAllowedDate"
                color="primary"
                :class="repeat === 'TWICEMONTHLY' ? 'datepicker-month datepicker-range' : ''"
                :no-title="repeat === 'TWICEMONTHLY'"
                :show-current="false"
                range
                @click:date="dateTwiceMonth.length === 2 ? $refs.menurefdatemonthbi.save(date) : ''"
                @change="(saveChangesAllowed = true), setRepeatOptions()"
              >
              </v-date-picker>
            </v-menu>
            <v-text-field
              :label="personioSchedule ? 'N/A' : 'N/A'"
              readonly
              outlined
              v-if="repeat === 'DAILY'"
              dense
              hide-details
              :disabled="repeat === 'DAILY'"
              value="Day or date does not apply"
              filled
              class="mb-5"
            ></v-text-field>
            <v-select
              v-model="menu3"
              :items="dayOptions"
              :label="personioSchedule ? 'Day of interval' : 'Day of sync'"
              placeholder="Please choose a day"
              outlined
              hide-details
              v-if="repeat === 'WEEKLY'"
              dense
              class="mb-5 mt-2"
              @change="(saveChangesAllowed = true), setRepeatOptions()"
            ></v-select>
            <!-- Temporarily removing Biweekly - until backend solution -->
            <!-- <v-select
              :items="dayOptions"
              v-model="menu5"
              :label="personioSchedule ? 'Day of interval' : 'Day of sync'"
              placeholder="Please choose a day"
              outlined
              hide-details
              @change="(saveChangesAllowed = true), setRepeatOptions()"
              dense
              class="mb-5 mt-2"
              v-if="repeat === 'BIWEEKLY'"
            ></v-select> -->
          </v-form>
        </div>
      </div>
    </v-card>
    <ModalWindow :is-open="changeScheduledSyncsModal" warning>
      <template #content>
        <p class="text-base">Are you sure that you want to cancel without saving your changes?</p>
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="changeScheduledSyncsModal = false"> No, continue </v-btn>
        <v-btn color="primary" @click="resetSchedule()"> Yes, cancel </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import {
  mdiChevronDoubleLeft,
  mdiShieldLock,
  mdiDatabase,
  mdiSync,
  mdiAlarm,
  mdiAccountPlus,
  mdiAccountEdit,
  mdiAccountMinus,
  mdiDownload,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiClose,
} from '@mdi/js';
import { ref, watch, onMounted, getCurrentInstance } from '@vue/composition-api';

import moment from 'moment';
import {
  putUpdatePersonio,
  getPersonioCompanyById,
  postPersonioSync,
  setAutosyncStatus,
} from '@/api/personio-connector';

import ModalWindow from '@/components/modal/ModalWindow.vue';
import { ADMIN_ACTIVITY_TYPE } from '@/constants';

const DAILY = 'DAILY';
const ONCE = 'ONCE';
const WEEKLY = 'WEEKLY';
const BIWEEKLY = 'BIWEEKLY';
const MONTHLY = 'MONTHLY';
const TWICEMONTHLY = 'TWICEMONTHLY';

export default {
  name: 'GlobalAdminPersonioDashboard',
  components: { ModalWindow },
  props: {
    currentSyncSchedule: {
      default: null,
    },
    personioCompanyId: {
      default: null,
    },
    lastSyncTimestamp: {
      default: null,
    },
    autosync: {
      default: null,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;
    const isEditing = ref(false);
    const isSyncRunning = ref(false);
    const changeScheduledSyncsModal = ref(false);
    const saveChangesAllowed = ref(false);
    const time = ref('00:00');
    const date = ref(null);
    const dateMonth = ref(null);
    const dateTwiceMonth = ref(null);
    const dateFormatted = ref(null);
    const repeat = ref(null);
    const alert = ref(false);
    const menu1 = ref(false);
    const menu2 = ref(false);
    const menu3 = ref(false);
    const menu4 = ref(false);
    const menu5 = ref(false);
    const personioSchedule = ref(null);
    const repeatOptions = ref([
      {
        text: `One time`,
        value: ONCE,
      },
      {
        text: 'Every day',
        value: DAILY,
      },
      {
        text: `Every week`,
        value: WEEKLY,
      },
      // {
      //   text: `Every two weeks`,
      //   value: BIWEEKLY,
      // },
      {
        text: `Every month`,
        value: MONTHLY,
      },
      {
        text: `Twice monthly`,
        value: TWICEMONTHLY,
      },
    ]);
    const nextSyncTimestamp = ref(null);
    const minAllowedDate = ref(null);
    const maxAllowedDate = ref(null);
    const minAllowedTime = ref(null);
    const dayOptions = ref([
      { text: 'Monday', value: 'Mon' },
      { text: 'Tuesday', value: 'Tue' },
      { text: 'Wednesday', value: 'Wed' },
      { text: 'Thursday', value: 'Thu' },
      { text: 'Friday', value: 'Fri' },
      { text: 'Saturday', value: 'Sat' },
      { text: 'Sunday', value: 'Sun' },
    ]);
    const userName =
      userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : `${userData.id}`;

    const fetchCompany = () => {
      getPersonioCompanyById(vm.$route.params.id).then((res) => {
        personioSchedule.value = res.data.schedule ? cronToReadable(res.data.schedule) : null;
      });
    };

    const syncNow = () => {
      isSyncRunning.value = true;
      postPersonioSync(props.personioCompanyId)
        .then(() => {
          signManualSync();
          isSyncRunning.value = false;
          vm.$store.commit('showMessage', {
            text: `Your manual sync was successful!`,
            color: 'teal',
            timeout: '5000',
          });
        })
        .catch(() => {
          vm.$store.commit('showMessage', {
            text: `Your manual sync failed!`,
            color: 'error',
            timeout: '5000',
          });
          signManualSync(false);
          isSyncRunning.value = false;
        });
    };

    const signManualSync = (success = true, errorLog = []) => {
      const msg = `Manual sync by ${userName} ${success ? 'was successful' : 'failed'}`;
      const status = success ? ADMIN_ACTIVITY_TYPE.INFO : ADMIN_ACTIVITY_TYPE.ERROR;
      vm.$emit('recordAction', {
        detail: { msg },
        type: status,
      });
    };

    const setAllowedDates = () => {
      if (repeat.value === ONCE) {
        minAllowedDate.value = moment().format('YYYY-MM-DD');
        maxAllowedDate.value = null;
      }
      if (repeat.value === MONTHLY || repeat.value === TWICEMONTHLY) {
        minAllowedDate.value = moment('2022-05-01').format('YYYY-MM-DD');
        maxAllowedDate.value = moment('2022-05-31').format('YYYY-MM-DD');
      }
    };

    const setMinAllowedTime = () => {
      if (repeat.value === ONCE) {
        if (
          moment([date.value, time.value], 'YYYY-MM-DD HH:mm').isSameOrBefore(moment(), time) ||
          moment([date.value], 'YYYY-MM-DD').isSameOrBefore(moment(), date)
        ) {
          time.value = moment().add(5, 'minutes').format('HH:mm');
          minAllowedTime.value = moment().add(5, 'minutes').format('HH:mm');
        } else {
          minAllowedTime.value = null;
        }
      } else {
        minAllowedTime.value = null;
      }
    };

    const setRepeatOptions = () => {
      setAllowedDates();
      setMinAllowedTime();
    };

    const setDefaultOptions = () => {
      repeat.value = null;
      date.value = new Date().toISOString().substr(0, 10);
      menu3.value = moment().format('ddd');
      menu5.value = moment().format('ddd');
      time.value = moment().add(5, 'minutes').format('HH:mm');
      dateMonth.value = `2022-05-${moment().format('DD')}`;
      dateTwiceMonth.value = ['2022-05-05', '2022-05-25'];
    };

    const resetSchedule = () => {
      saveChangesAllowed.value = false;
      isEditing.value = false;
      changeScheduledSyncsModal.value = false;
      setDefaultOptions();
      fetchCompany();
    };

    const saveSchedule = () => {
      const cronExpression = dateToCron();
      if (!cronExpression) return;

      putUpdatePersonio(props.personioCompanyId, {
        portalCompanyId: props.personioCompanyId,
        schedule: cronExpression,
      })
        .then(() => {
          if (personioSchedule.value) {
            vm.$emit('recordAction', {
              detail: {
                msg: `${userName} changed automatic sync`,
                hint: `${userName} changed schedule to: ${cronToReadable(cronExpression)}`,
              },
              type: ADMIN_ACTIVITY_TYPE.WARNING,
            });
          } else {
            vm.$emit('recordAction', {
              detail: {
                msg: `${userName} set up automatic sync`,
              },
              type: ADMIN_ACTIVITY_TYPE.INFO,
            });
          }

          setAutosyncStatus(props.personioCompanyId, true);
          fetchCompany();
          alert.value = true;
          isEditing.value = false;
          saveChangesAllowed.value = false;
        })
        .catch((err) => {
          isEditing.value = true;
          saveChangesAllowed.value = true;
          vm.$store.commit('showMessage', {
            text: err,
            color: 'red',
            timeout: '5000',
          });
        });
    };

    const dateToCron = () => {
      let cron;
      const onTime = time.value.split(':');
      const onDay = moment(date.value).format('ddd');
      const onDate = moment(date.value).format('D');
      const onDateMonth = moment(dateMonth.value).format('D');
      const onDateTwiceMonth = `${moment(dateTwiceMonth.value[0]).format('D')},${moment(dateTwiceMonth.value[1]).format(
        'D',
      )}`;
      const onMonth = moment(date.value).format('MMM');
      const onYear = moment(date.value).year();
      if (repeat.value === DAILY) {
        cron = `0 ${onTime[1]} ${onTime[0]} ? * * *`;
      } else if (repeat.value === WEEKLY) {
        cron = `0 ${onTime[1]} ${onTime[0]} ? * ${menu3.value} *`;
      } else if (repeat.value === BIWEEKLY) {
        cron = `0 ${onTime[1]} ${onTime[0]} * * ${menu5.value} ?`;
      } else if (repeat.value === MONTHLY) {
        cron = `0 ${onTime[1]} ${onTime[0]} ${onDateMonth} * ? *`;
      } else if (repeat.value === TWICEMONTHLY) {
        cron = `0 ${onTime[1]} ${onTime[0]} ${onDateTwiceMonth} * ? *`;
      } else if (repeat.value === ONCE) {
        cron = `0 ${onTime[1]} ${onTime[0]} ${onDate} ${onMonth} ? ${onYear}`;
      } else {
        return false;
      }

      return cron;
    };

    const cronToReadable = (cronExpression) => {
      let [e, minutes, hours, dayOfMonth, month, dayOfWeek, year] = cronExpression.split(' ');
      let cronToString = '';
      cronToString += `${hours}:${minutes} `;
      time.value = hours && minutes ? `${hours}:${minutes}` : '00:00';
      menu3.value = 'Mon';
      const now = moment();
      dayOfMonth = dayOfMonth.split(/\s*,\s*/);
      // date.value = moment(cron).format("YYYY-MM-DD");
      const cron = moment().set({
        year,
        month,
        date: dayOfMonth,
        hour: hours,
        minute: minutes,
      });

      // ONCE
      if (year !== '*' && dayOfWeek === '?') {
        repeat.value = 'ONCE';
        if (moment(cron).isAfter(moment(now))) {
          nextSyncTimestamp.value = moment(cron).calendar(null, {
            sameDay: '[Today], HH:mm a',
            nextDay: '[Tomorrow], HH:mm a',
            nextWeek: 'DD.MM.YY, HH:mm a',
            sameElse: 'DD.MM.YY, HH:mm a',
          });
        } else {
          nextSyncTimestamp.value = 'No sync scheduled';
        }
        cronToString = `One time, ${moment(cron).calendar(null, {
          sameDay: '[Today], HH:mm a',
          nextDay: '[Tomorrow], HH:mm a',
          nextWeek: 'DD.MM.YY, HH:mm a',
          sameElse: 'DD.MM.YY, HH:mm a',
        })}`;
        date.value = moment(cron).format('YYYY-MM-DD');
        return cronToString;
      }

      // DAILY
      if (dayOfWeek === '*' && month === '*' && year === '*') {
        cronToString = `Daily, ${moment(time.value, 'HH:mm').format('HH:mm a')}`;
        repeat.value = 'DAILY';
        const today = moment().isSameOrBefore(moment(time.value, 'HH:mm'));
        // Necessary for now to add if here
        if (today) {
          nextSyncTimestamp.value =
            moment(date.value, 'HH:mm').calendar(null, {
              sameDay: '[Today], ',
              nextDay: '[Tomorrow], ',
              nextWeek: 'DD.MM.YY, ',
              sameElse: 'DD.MM.YY, ',
            }) + moment(time.value, 'HH:mm').format('HH:mm a');
        } else {
          nextSyncTimestamp.value =
            moment(date.value, 'HH:mm').calendar(null, {
              sameDay: '[Tomorrow], ',
              nextDay: '[Tomorrow], ',
              nextWeek: 'DD.MM.YY, ',
              sameElse: 'DD.MM.YY, ',
            }) + moment(time.value, 'HH:mm').format('HH:mm a');
        }

        return cronToString;
      }

      // WEEKLY
      if (month === '*' && year === '*' && dayOfWeek !== '?') {
        repeat.value = 'WEEKLY';
        nextSyncTimestamp.value =
          moment()
            .day(dayOfWeek)
            .calendar(null, {
              lastDay() {
                return `[${moment().day(dayOfWeek).add(1, 'week').format('DD.MM.YY')},` + `] `;
              },
              sameDay() {
                if (moment(time.value, 'HH:mm').isSameOrBefore(moment())) {
                  return `[${moment().day(dayOfWeek).add(1, 'week').format('DD.MM.YY')},` + `] `;
                }
                return '[Today], ';
              },
              nextDay: '[Tomorrow], ',
              nextWeek: 'DD.MM.YY, ',
              lastWeek() {
                return `[${moment().day(dayOfWeek).add(1, 'week').format('DD.MM.YY')},` + `] `;
              },
              sameElse: 'DD.MM.YY, ',
            }) + moment(time.value, 'HH:mm').format('HH:mm a');
        menu3.value = dayOfWeek;
        cronToString = `Weekly on ${moment().day(dayOfWeek).calendar(null, {
          lastDay: 'dddd, ',
          sameDay: 'dddd, ',
          nextDay: 'dddd, ',
          nextWeek: 'dddd, ',
          sameElse: 'dddd, ',
          lastWeek: 'dddd, ',
        })}${moment(time.value, 'HH:mm').format('HH:mm a')}`;
        return cronToString;
      }
      // BIWEEKLY
      if (month === '*' && year === '?') {
        repeat.value = 'BIWEEKLY';
        nextSyncTimestamp.value =
          moment()
            .day(dayOfWeek)
            .calendar(null, {
              lastDay() {
                return `[${moment().day(dayOfWeek).add(1, 'week').format('DD.MM.YY')},` + `] `;
              },
              sameDay() {
                if (moment(time.value, 'HH:mm').isSameOrBefore(moment())) {
                  return `[${moment().day(dayOfWeek).add(1, 'week').format('DD.MM.YY')},` + `] `;
                }
                return '[Today], ';
              },
              nextDay: '[Tomorrow], ',
              nextWeek: 'DD.MM.YY, ',
              lastWeek() {
                return `[${moment().day(dayOfWeek).add(1, 'week').format('DD.MM.YY')},` + `] `;
              },
              sameElse: 'DD.MM.YY, ',
            }) + moment(time.value, 'HH:mm').format('HH:mm a');
        menu5.value = dayOfWeek;
        cronToString = `Biweekly on ${moment().day(dayOfWeek).calendar(null, {
          lastDay: 'dddd, ',
          sameDay: 'dddd, ',
          nextDay: 'dddd, ',
          nextWeek: 'dddd, ',
          sameElse: 'dddd, ',
          lastWeek: 'dddd, ',
        })}${moment(time.value, 'HH:mm').format('HH:mm a')}`;
        return cronToString;
      }
      // MONTHLY
      if (month === '*' && year === '*' && dayOfWeek === '?' && dayOfMonth.length === 1) {
        repeat.value = 'MONTHLY';

        if (moment(cron).date(dayOfMonth).isSameOrBefore(now, 'DD')) {
          nextSyncTimestamp.value =
            moment(dayOfMonth, 'DD').add(1, 'month').calendar(null, {
              sameDay: '[Today], ',
              nextDay: '[Tomorrow], ',
              nextWeek: 'DD.MM.YY, ',
              sameElse: 'DD.MM.YY, ',
            }) + moment(time.value, 'HH:mm').format('HH:mm a');
          dateMonth.value = moment(`2022-05-${dayOfMonth}`).format('YYYY-MM-DD');
        } else {
          nextSyncTimestamp.value =
            moment(cron, 'YYYY').date(dayOfMonth).month(moment().get('month')).calendar(null, {
              sameDay: '[Today], ',
              nextDay: '[Tomorrow], ',
              nextWeek: 'DD.MM.YY, ',
              sameElse: 'DD.MM.YY, ',
            }) + moment(time.value, 'HH:mm').format('HH:mm a');
          dateMonth.value = moment(`2022-05-${dayOfMonth}`).format('YYYY-MM-DD');
        }
        cronToString = `Monthly on the ${moment(dateMonth.value).format('Do')}, ${moment(time.value, 'HH:mm').format(
          'HH:mm a',
        )}`;
        return cronToString;
      }

      // TWICEMONTHLY
      if (month === '*' && year === '*' && dayOfWeek === '?' && dayOfMonth.length === 2) {
        repeat.value = 'TWICEMONTHLY';

        if (moment(cron).date(dayOfMonth[0]).isSameOrBefore(now, 'DD')) {
          nextSyncTimestamp.value =
            moment(dayOfMonth[1], 'DD').format('DD.MM.YY, ') + moment(time.value, 'HH:mm').format('HH:mm a');
          dateTwiceMonth.value = [
            moment(`2022-05-${dayOfMonth[0]}`).format('YYYY-MM-DD'),
            moment(`2022-05-${dayOfMonth[1]}`).format('YYYY-MM-DD'),
          ];
        } else {
          nextSyncTimestamp.value =
            moment(cron, 'YYYY').date(dayOfMonth[0]).month(moment().get('month')).calendar(null, {
              sameDay: '[Today], ',
              nextDay: '[Tomorrow], ',
              nextWeek: 'DD.MM.YY, ',
              sameElse: 'DD.MM.YY, ',
            }) + moment(time.value, 'HH:mm').format('HH:mm a');
          dateTwiceMonth.value = [
            moment(`2022-05-${dayOfMonth[0]}`).format('YYYY-MM-DD'),
            moment(`2022-05-${dayOfMonth[1]}`).format('YYYY-MM-DD'),
          ];
        }
        cronToString = `Bimonthly on the ${moment(dateTwiceMonth.value[0]).format('Do')}/
        ${moment(moment(dateTwiceMonth.value[1]).format('Do'))} , ${moment(time.value, 'HH:mm').format('HH:mm a')}`;
        return cronToString;
      }
      cronToString += ' ';

      return cronToString;
    };

    onMounted(() => {
      setDefaultOptions();
      personioSchedule.value = props.currentSyncSchedule ? cronToReadable(props.currentSyncSchedule) : null;
      setRepeatOptions();
    });

    watch(dateTwiceMonth, (val) => {
      if (moment(dateTwiceMonth.value[1]).isSameOrBefore(moment(dateTwiceMonth.value[0]))) {
        dateTwiceMonth.value = [dateTwiceMonth.value[1], dateTwiceMonth.value[0]];
      }
    });

    watch(date, (val) => {
      dateFormatted.value = moment.utc(val).local().format('DD.MM.YYYY');
    });

    return {
      isSyncRunning,
      alert,
      syncNow,
      moment,
      isEditing,
      changeScheduledSyncsModal,
      saveChangesAllowed,
      time,
      date,
      dateMonth,
      dateTwiceMonth,
      menu2,
      menu1,
      dateFormatted,
      repeat,
      repeatOptions,
      saveSchedule,
      personioSchedule,
      cronToReadable,
      nextSyncTimestamp,
      setAllowedDates,
      setMinAllowedTime,
      resetSchedule,
      setRepeatOptions,
      setDefaultOptions,
      dayOptions,
      menu3,
      menu4,
      menu5,
      minAllowedTime,
      minAllowedDate,
      maxAllowedDate,

      icons: {
        mdiChevronDoubleLeft,
        mdiShieldLock,
        mdiDatabase,
        mdiSync,
        mdiAlarm,
        mdiAccountPlus,
        mdiAccountEdit,
        mdiAccountMinus,
        mdiDownload,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiClose,
      },
    };
  },
};
</script>

<style lang="scss">
.datepicker-month {
  padding-top: 8px;
  padding-bottom: 8px;
  &.datepicker-range {
    .v-btn:not(.v-date-picker--first-in-range, .v-date-picker--last-in-range) {
      border-color: white;
      &:before {
        background: white;
        opacity: 1;
        border-color: white !important;
      }
      .v-btn__content {
        color: black;
      }
    }
  }

  .v-date-picker-table {
    display: flex;
    thead tr th {
      display: none;
    }
  }
  .v-date-picker-header {
    display: none;
  }
}
.mh-544 {
  min-height: 544px;
}
.mh-260 {
  min-height: 260px;
  max-height: 260px;
}
.personio {
  position: relative;
  .front,
  .back {
    position: absolute;
    height: 100%;
    width: 100%;

    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: all ease-in-out 0.3s;
    // overflow: hidden;
  }
  .back {
    transform: rotateY(180deg);
  }
  &.is-editing {
    .front {
      transform: rotateY(-180deg);
    }
    .back {
      transform: rotateY(0deg);
    }
  }

  .back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: ease-in-out 0.3s;
  }
  &-overview {
    position: relative;

    &__edit {
      position: absolute;
      left: 16px;
      bottom: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #2830ce;
      cursor: pointer;
    }

    &__close {
      width: calc(100% - 214px);
      position: absolute;
      top: 10px;
      display: flex;
      justify-content: flex-end;
    }

    &__card {
      perspective: 1000px;

      &-schedule {
        margin-top: 20px;
        width: calc(100% - 214px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}
.personioScheduleForm {
  width: 100%;
}

.alert-personio {
  z-index: 1000;
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  .v-alert__wrapper {
    align-items: flex-start !important;
  }
  .v-btn {
    margin-top: -5px !important;
  }
  .v-icon {
    height: 16px !important;
    width: 16px !important;
  }
}
</style>
