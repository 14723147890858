import axios from '@/plugins/axios';
import { fetchHRManagerByCompany } from '@/api/fusion-auth';

const buildQuery = (offset, limit, search, syncStatus) => {
  let query = '';

  if (offset) {
    query += `&offset=${offset}`;
  }

  if (limit) {
    query += `&limit=${limit}`;
  }

  if (search) {
    query += `&searchString=${search}`;
  }

  if (typeof syncStatus === 'boolean') {
    query += `&sync=${syncStatus}`;
  }

  if (typeof syncStatus === 'string') {
    query += `&${syncStatus}`;
  }

  return query;
};

export const getDeguraEnums = () => {
  return axios.get('/personio-connector/enumfields');
};

export const getPersonioActivityTimeline = (companyId, offset = 0, limit = 1000) => {
  const q = buildQuery(offset, limit);
  return axios.get(`/personio-connector/activities?companyId=${companyId}&${q}`);
};

export const signPersonioActivity = (companyId, userId, detail, status) => {
  return axios.post(`/personio-connector/activities`, {
    companyId,
    userId,
    detail,
    status,
  });
};

export const getPreviewSyncReport = (runId) => {
  return axios.get(`personio-connector/sync/${runId}/report`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'blob',
      Accept: 'application/octet-stream',
    },
  });
};

export const setAutosyncStatus = (companyId, status) => {
  return axios.put(`/personio-connector/${companyId}/togglesync`, {
    autosync: status,
  });
};

export const getPersonioMetrics = () => {
  return axios.get('/personio-connector/metrics');
};

export const getPersonioAttributes = (id) => {
  return axios.get(`/personio-connector/attributes?companyId=${id}`);
};

export const getPersonioFields = (id) => {
  return axios.get(`/personio-connector/fields/${id}`);
};

export const getPortalAttributes = () => {
  return axios.get(`/personio-connector/attributes/portal`);
};

export const getPersonioCompaniesList = async (offset = 0, limit = 15, search, syncStatus) => {
  const q = buildQuery(offset, limit, search, syncStatus);
  const response = await axios.get(`/personio-connector/companies?${q}`);

  const page = await Promise.all(
    response.data.page.map(async (item) => {
      const hrPeople = await fetchHRManagerByCompany(item.portalCompanyId);

      return {
        ...item,
        hr: {
          ...hrPeople.data.users,
        },
      };
    }),
  );

  return {
    data: {
      ...response.data,
      page,
    },
  };
};

export const getPersonioCompanyById = (companyId) => axios.get(`/personio-connector?companyId=${companyId}`);

export const postPersonioSync = (companyId) =>
  axios.post(`/personio-connector/sync?companyId=${companyId}&execute=true`);

export const postPersonioSyncDry = (companyId) =>
  axios.post(`/personio-connector/sync?companyId=${companyId}&execute=false`);

export const postConnectPersonio = (payload) => axios.post('/personio-connector/', payload);

export const putUpdatePersonio = (companyId, payload) => {
  delete payload.portalCompanyId;

  return axios.put(`/personio-connector/${companyId}`, payload, {
    headers: {
      'Content-type': 'application/json',
    },
  });
};

export const putResetPersonioCredentials = (companyId) => axios.put(`/personio-connector/${companyId}/reset`);

export const postValidateCredentials = (creds) => axios.post(`/personio-connector/auth`, creds);
