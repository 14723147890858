<template>
  <div class="my-bav my-bav__table d-flex">
    <v-card class="my-bav__table-card flex-grow-1 v-card--no-border">
      <v-card-text class="d-flex justify-end flex-wrap pb-0 pt-0">
        <span class="pb-6" :class="[{ 'py-4': $vuetify.breakpoint.smAndDown }]">
          <a
            v-if="!loading"
            color="primary"
            class="text-decoration-underline mr-3 px-0 v-card--no-bg d-flex align-items-center"
            @click="$emit('getPreviewFile')"
          >
            <v-icon size="22" class="mr-1 primary--text" color="primary">
              {{ icons.mdiDownloadOutline }}
            </v-icon>
            <span> Download preview </span>
          </a>
        </span>
      </v-card-text>

      <v-data-table
        v-if="!loading"
        :key="rerenderKey"
        :items="syncReport.report"
        :headers="headers"
        :mobile-breakpoint="0"
        :loading="loading"
        :sort-by.sync="sortingOptions"
        :sort-desc.sync="sortingIsDesc"
        class="text-no-wrap cursor-pointer flex-grow-1"
        disable-pagination
        hide-default-footer
        fixed-header
        height="1500"
        @update:items-per-page="setLimit"
        @pagination="handlePagination"
      >
        <template #[`item.status`]="{ item }">
          <span style="text-transform: capitalize">{{ item.status.toLowerCase() }}</span>
        </template>
        <template #[`item.salutation`]="{ item }">
          <span> {{ $t(SALUTATION_TRANSLATION_MAP[item.salutation]) }}</span>
        </template>
        <template slot="no-data">
          <div class="my-5">
            <p>{{ $t('myBavNoResults') }}</p>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiDownloadOutline,
  mdiInformationOutline,
  mdiCheckCircle,
  mdiUploadOutline,
  mdiMagnify,
  mdiPaperclip,
  mdiDelete,
} from '@mdi/js';

import moment from 'moment';

import { ref, getCurrentInstance, computed, watch } from '@vue/composition-api';
import { SALUTATION_TRANSLATION_MAP } from '@/constants';

export default {
  name: 'PersonioPreviewTable',
  props: {
    companySelected: {
      required: true,
    },
    syncReport: {
      required: true,
    },
    loading: {},
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const currentPage = ref(1);
    const rerenderKey = ref(0);
    const sortingOptions = ref(['personalDetails.firstName']);
    const statusFilter = ref('');
    const searchQuery = ref('');
    const sortingIsDesc = ref(false);
    const { activeRole } = vm.$store.getters;

    const headers = computed(() => {
      return [
        {
          text: 'Employee ID',
          value: 'employeeId',
          sortable: true,
          class: 'text-uppercase',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true,
          class: 'text-uppercase',
        },
        {
          text: 'Salutation',
          value: 'salutation',
          sortable: true,
          class: 'text-uppercase',
        },
        {
          text: 'First name',
          value: 'preName',
          sortable: true,
          class: 'text-uppercase',
        },
        {
          text: 'Last name',
          value: 'surName',
          sortable: true,
          class: 'text-uppercase',
        },
        {
          text: 'Email personal',
          value: 'emailPersonal',
          sortable: true,
          class: 'text-uppercase',
        },
        {
          text: 'Email business',
          value: 'emailBusiness',
          sortable: true,
          class: 'text-uppercase',
        },
        {
          text: 'Phone number',
          value: 'phoneNumber',
          sortable: true,
          class: 'text-uppercase',
        },
        {
          text: 'Personal number',
          value: 'personalNumber',
          sortable: true,
          class: 'text-uppercase',
        },
      ];
    });

    const setStatus = () => {
      vm.$store.dispatch('employees/filterStatus', statusFilter.value);
    };

    const setSorting = (fieldName, direction) => {
      vm.$store.commit('employees/SET_SORT', direction);
      vm.$store.dispatch('employees/sort', fieldName);
    };

    const setLimit = (limit) => {
      vm.$store.dispatch('employees/setLimit', limit);
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) onNextPage();
      if (currentPage.value > event.page) onPreviousPage();

      currentPage.value = event.page;
    };

    const onNextPage = () => {
      vm.$store.dispatch('employees/nextPage');
    };

    watch(sortingIsDesc, () => {
      if (sortingOptions.value.length > 0) {
        const direction = sortingIsDesc.value ? 'DESC' : 'ASC';
        setSorting(sortingOptions.value[0], direction);
      }
    });

    return {
      rerenderKey,
      currentPage,
      headers,
      searchQuery,
      statusFilter,
      moment,
      sortingOptions,
      sortingIsDesc,
      setStatus,
      setLimit,
      handlePagination,
      activeRole,
      SALUTATION_TRANSLATION_MAP,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDownloadOutline,
        mdiPencilOutline,
        mdiInformationOutline,
        mdiCheckCircle,
        mdiUploadOutline,
        mdiMagnify,
        mdiPaperclip,
        mdiDelete,
      },
    };
  },
};
</script>

<style lang="scss">
.min-width-text {
  min-width: 160px;
}
.min-width-card {
  min-width: 500px;
}
.th {
  max-width: 280px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: block;
}

.my-bav {
  &__table {
    .v-select__selection {
      color: #2830ce !important;
    }
    th {
      background: #c9ccf4 !important;
      border-top: 1px solid rgba(40, 48, 206, 0.15) !important;
      border-color: #2830ce !important;
      border-bottom: none !important;
      span {
        color: #2830ce !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
      }
    }
    tr,
    td {
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    tr:nth-child(even) {
      background: rgba(40, 48, 206, 0.05) !important;
      &:hover {
        background: rgba(40, 48, 206, 0.05) !important;
      }
    }
    &-card {
      border-radius: 3px !important;
      /*margin-top: 31px;*/
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    &-heading {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #505a68;
    }
    &-filter {
      /*max-width: 320px;*/
      min-width: 320px;
    }
    &-info {
      padding: 10px 8px;
      border: 1px solid #2830ce;
      border-radius: 3px;
      margin: 8px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      background-color: white;
      /*transform: translateX(50%) translateY(10px);*/
      color: #505a68;

      ul {
        padding: 0;
        padding-left: 12px;
      }
      &-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #2830ce;
        margin-bottom: 3px;
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .my-bav {
    &__table {
      table {
        border: 1px solid rgba(40, 48, 206, 0.15) !important;
      }
    }
    .v-data-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    .v-data-footer__icons-after,
    .v-data-footer__icons-before {
      max-width: 16px;
      max-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-data-footer__select {
      min-width: unset !important;
    }
    .v-data-footer__pagination {
      margin: 0 10px;
    }
  }
}
</style>
