<template>
  <v-col cols="12" md="6" sm="12">
    <v-card class="personio-overview__card">
      <div class="personio-overview__card-title">
        <span>Sync Preview </span>
        <span>
          <v-progress-circular
            v-if="!lastDryRunId"
            indeterminate
            size="14"
            width="2"
            color="primary"
          ></v-progress-circular>
        </span>
      </div>
      <div class="d-flex mt-5 justify-space-between">
        <div class="personio-overview__card-tile">
          <div class="personio-overview__report-circle">
            <v-icon size="38" class="personio-overview__report-icon">
              {{ icons.mdiAccountPlus }}
            </v-icon>
          </div>
          <div v-if="nextSyncPreview" class="personio-overview__report-number">{{ nextSyncPreview[0] }}</div>
          <div v-else class="personio-overview__report-number">-</div>
        </div>
        <div class="personio-overview__card-tile">
          <div class="personio-overview__report-circle">
            <v-icon size="38" class="personio-overview__report-icon">
              {{ icons.mdiAccountEdit }}
            </v-icon>
          </div>
          <div v-if="nextSyncPreview" class="personio-overview__report-number">{{ nextSyncPreview[1] }}</div>
          <div v-else class="personio-overview__report-number">-</div>
        </div>
        <div class="personio-overview__card-tile">
          <div class="personio-overview__report-circle personio-overview__report-circle--warning">
            <v-icon size="38" class="personio-overview__report-icon">
              {{ icons.mdiAccountMinus }}
            </v-icon>
          </div>
          <div v-if="nextSyncPreview" class="personio-overview__report-number">{{ nextSyncPreview[2] }}</div>
          <div v-else class="personio-overview__report-number">-</div>
        </div>
      </div>
      <v-icon
        size="20"
        color="#2830CE"
        :disabled="!lastDryRunId"
        class="mt-5 cursor-pointer"
        @click="getPreviewFile(lastDryRunId)"
        >{{ icons.mdiDownload }}</v-icon
      >
    </v-card>
  </v-col>
</template>

<script>
import fileDownload from 'js-file-download';
import moment from 'moment';
import { mdiAccountPlus, mdiAccountEdit, mdiAccountMinus, mdiDownload } from '@mdi/js';
import { toRefs } from '@vue/composition-api';
import { getPreviewSyncReport } from '@/api/personio-connector';

export default {
  name: 'NextSync',
  props: ['personioCompany', 'nextSyncPreview', 'lastDryRunId'],
  setup(props) {
    const { personioCompany } = toRefs(props);

    const getPreviewFile = (id) => {
      getPreviewSyncReport(id).then((res) => {
        fileDownload(res.data, `${personioCompany.value.name}_preview_sync_${moment().format('DD.MM.YYYY')}.xls`);
      });
    };

    return {
      getPreviewFile,
      icons: {
        mdiAccountPlus,
        mdiAccountEdit,
        mdiAccountMinus,
        mdiDownload,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/pages/_dashboard.scss';
</style>
