import { render, staticRenderFns } from "./MappedFields.vue?vue&type=template&id=cebf6f26&scoped=true&"
import script from "./MappedFields.vue?vue&type=script&lang=js&"
export * from "./MappedFields.vue?vue&type=script&lang=js&"
import style0 from "./MappedFields.vue?vue&type=style&index=0&id=cebf6f26&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cebf6f26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCol,VSimpleTable})
