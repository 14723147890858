var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-value pb-4"},[_c('v-data-table',{key:_vm.rerenderKey,staticClass:"text-no-wrap v-data-table--personio",attrs:{"items":_vm.loadedFields,"headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.company_field",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-flex align-items-center"},[_c('v-tooltip',{attrs:{"right":"","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',[_vm._v(_vm._s(header.text))]),_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"22"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformation)+" ")])]}}],null,true)},[_c('div',{staticClass:"step-value__info"},[_c('span',{staticClass:"text--blue"},[_vm._v("It may happen that the company has more values than we do. In this case, the additional company value must be assigned to an existing degura value.")])])])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"border-right align-items-start",staticStyle:{"vertical-align":"top","width":"194px"}},[_c('div',{staticClass:"mt-4 step-value__title"},[_vm._v(_vm._s(item.degura_field)+" <> "+_vm._s(item.company_field))])]),_c('td',{class:{
            'pb-95': Object.values(item.mapping).every(function (x) { return !!x; }) && item.company_values.length > 0,
          }},[_c('span',{staticClass:"d-flex my-2"},[_c('div',_vm._l((item.degura_values),function(field,index){return _c('div',{key:index + 'f',staticClass:"d-flex align-items-center my-5",style:({ opacity: !item.mapping[field].length && !item.company_values.length ? 0.5 : 1 })},[_c('span',{staticClass:"mr-3"},[_vm._v("Map:")]),_c('div',[_c('v-text-field',{staticClass:"step-value__input",attrs:{"outlined":"","readonly":"","dense":"","hide-details":""},model:{value:(item.degura_values[index]),callback:function ($$v) {_vm.$set(item.degura_values, index, $$v)},expression:"item.degura_values[index]"}})],1)])}),0)])]),_c('td',{staticClass:"px-0",class:{
            'pb-95': Object.values(item.mapping).every(function (x) { return !!x; }) && item.company_values.length > 0,
          }},[_c('span',{staticClass:"d-flex"},[_c('div',_vm._l((item.degura_values),function(field,index){return _c('div',{key:index + 'a',staticClass:"d-flex align-items-center my-5",style:({ opacity: !item.mapping[field].length && !item.company_values.length ? 0.5 : 1 })},[_c('span',{staticClass:"mr-3"},[_vm._v("To:")]),_c('v-select',{staticClass:"mr-4 step-value__input",attrs:{"items":item.company_values,"multiple":"","outlined":"","dense":"","hide-details":"","disabled":!item.mapping[field].length && _vm.canContinue,"placeholder":item.mapping[field] ? '' : 'Select'},on:{"blur":function($event){return _vm.getAvailableValueOptions(item)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return _vm._l((item.mapping[field]),function(chip,index){return _c('div',{key:index},[_c('v-chip',{staticClass:"step-value__chip mb-2",attrs:{"close":"","close-icon":_vm.icons.mdiClose},on:{"click:close":function($event){return _vm.deleteChip(item, field, index)}}},[_c('span',[_vm._v(_vm._s(chip))])])],1)})},proxy:true}],null,true),model:{value:(item.mapping[field]),callback:function ($$v) {_vm.$set(item.mapping, field, $$v)},expression:"item.mapping[field]"}}),(item.mapping[field])?_c('v-icon',{staticClass:"reset",attrs:{"size":"22","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e()],1)}),0)]),_c('div',{staticClass:"step-value__warning hidden px-5 py-6",class:{
              shown: Object.values(item.mapping).every(function (x) { return !!x; }) && item.company_values.length > 0,
            }},[_c('v-icon',{attrs:{"color":"white","size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircle)+" ")]),_c('div',{staticClass:"ml-5 step-value__warning-text"},[_vm._v(" There is still a company value left, which might has to be assigned to a value of Degura. ")])],1)])])]}}],null,true),model:{value:(_vm.selectedFields),callback:function ($$v) {_vm.selectedFields=$$v},expression:"selectedFields"}},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"my-5"},[_c('p',[_vm._v(_vm._s(_vm.$t('myBavNoResults')))])])])],2),(!_vm.isEdit)?_c('div',{staticClass:"d-flex mt-10"},[_c('v-btn',{staticClass:"flex-button step-value__btn",attrs:{"color":"primary","depressed":"","outlined":""},on:{"click":function($event){return _vm.$emit('stepBack')}}},[_vm._v(" Back to field mapping ")]),_c('v-spacer'),_c('v-btn',{staticClass:"flex-button step-value__btn",attrs:{"color":"primary","disabled":!_vm.canContinue,"type":"submit"},on:{"click":_vm.submitStep}},[_vm._v(" Continue to filter selection ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }