<template>
  <v-col cols="12" md="6" sm="12">
    <v-card class="personio-overview__card mh-544">
      <div class="personio-overview__card-title">Activity timeline (last half year)</div>
      <div class="personio-overview__stepper">
        <div v-for="(item, index) in syncHistory" :key="index + 1" class="personio-overview__step">
          <div class="personio-overview__step-status">
            <div
              class="personio-overview__step-status-circle"
              :class="[
                { 'circle--success': item.status === ADMIN_ACTIVITY_TYPE.INFO },
                { 'circle--warning': item.status === ADMIN_ACTIVITY_TYPE.WARNING },
                { 'circle--error': item.status === ADMIN_ACTIVITY_TYPE.ERROR },
              ]"
            ></div>
          </div>
          <div class="personio-overview__step-title">
            <span>{{ item.detail.msg }}</span>
            <span class="sync-date">{{ item.activityDate }}</span>
          </div>
          <div v-if="item.detail.hint" class="personio-overview__step-description">{{ item.detail.hint }}</div>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import { ADMIN_ACTIVITY_TYPE } from '@/constants';

export default {
  name: 'ActivityTimeline',
  props: ['syncHistory'],
  setup() {
    return {
      ADMIN_ACTIVITY_TYPE,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/pages/_dashboard.scss';
</style>
