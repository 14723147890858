<template>
  <div class="step-value pb-4">
    <v-data-table
      :key="rerenderKey"
      v-model="selectedFields"
      :items="loadedFields"
      :headers="headers"
      :loading="loading"
      class="text-no-wrap v-data-table--personio"
      hide-default-footer
    >
      <template #[`header.company_field`]="{ header }">
        <span class="d-flex align-items-center">
          <v-tooltip right color="transparent">
            <template #activator="{ on, attrs }">
              <span>{{ header.text }}</span>
              <v-icon size="22" class="ml-1" v-bind="attrs" v-on="on">
                {{ icons.mdiInformation }}
              </v-icon>
            </template>
            <div class="step-value__info">
              <span class="text--blue"
                >It may happen that the company has more values than we do. In this case, the additional company value
                must be assigned to an existing degura value.</span
              >
            </div>
          </v-tooltip>
        </span>
      </template>

      <template v-slot:item="{ item }">
        <tr>
          <td class="border-right align-items-start" style="vertical-align: top; width: 194px">
            <div class="mt-4 step-value__title">{{ item.degura_field }} <> {{ item.company_field }}</div>
          </td>
          <td
            :class="{
              'pb-95': Object.values(item.mapping).every((x) => !!x) && item.company_values.length > 0,
            }"
          >
            <span class="d-flex my-2">
              <div>
                <div
                  v-for="(field, index) in item.degura_values"
                  :key="index + 'f'"
                  :style="{ opacity: !item.mapping[field].length && !item.company_values.length ? 0.5 : 1 }"
                  class="d-flex align-items-center my-5"
                >
                  <span class="mr-3">Map:</span>
                  <div>
                    <v-text-field
                      v-model="item.degura_values[index]"
                      outlined
                      readonly
                      class="step-value__input"
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </span>
          </td>
          <td
            class="px-0"
            :class="{
              'pb-95': Object.values(item.mapping).every((x) => !!x) && item.company_values.length > 0,
            }"
          >
            <span class="d-flex">
              <div>
                <div
                  v-for="(field, index) in item.degura_values"
                  :key="index + 'a'"
                  :style="{ opacity: !item.mapping[field].length && !item.company_values.length ? 0.5 : 1 }"
                  class="d-flex align-items-center my-5"
                >
                  <span class="mr-3">To:</span>
                  <v-select
                    v-model="item.mapping[field]"
                    :items="item.company_values"
                    multiple
                    class="mr-4 step-value__input"
                    outlined
                    dense
                    hide-details
                    :disabled="!item.mapping[field].length && canContinue"
                    :placeholder="item.mapping[field] ? '' : 'Select'"
                    @blur="getAvailableValueOptions(item)"
                  >
                    <template v-slot:prepend-inner>
                      <div v-for="(chip, index) in item.mapping[field]" :key="index">
                        <v-chip
                          class="step-value__chip mb-2"
                          close
                          :close-icon="icons.mdiClose"
                          @click:close="deleteChip(item, field, index)"
                        >
                          <span>{{ chip }}</span>
                        </v-chip>
                      </div>
                    </template>
                  </v-select>

                  <v-icon v-if="item.mapping[field]" size="22" class="reset" color="success">
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                </div>
              </div>
            </span>

            <div
              class="step-value__warning hidden px-5 py-6"
              :class="{
                shown: Object.values(item.mapping).every((x) => !!x) && item.company_values.length > 0,
              }"
            >
              <v-icon color="white" size="20">
                {{ icons.mdiAlertCircle }}
              </v-icon>
              <div class="ml-5 step-value__warning-text">
                There is still a company value left, which might has to be assigned to a value of Degura.
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template slot="no-data">
        <div class="my-5">
          <p>{{ $t('myBavNoResults') }}</p>
        </div>
      </template>
    </v-data-table>

    <div v-if="!isEdit" class="d-flex mt-10">
      <v-btn color="primary" class="flex-button step-value__btn" depressed outlined @click="$emit('stepBack')">
        Back to field mapping
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled="!canContinue"
        class="flex-button step-value__btn"
        type="submit"
        @click="submitStep"
      >
        Continue to filter selection
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, toRefs, onMounted, getCurrentInstance } from '@vue/composition-api';
import { mdiDelete, mdiCheckCircle, mdiAlertCircle, mdiPlusCircle, mdiClose, mdiInformation } from '@mdi/js';
import {
  getDeguraEnums,
  getPersonioCompanyById,
  getPersonioFields,
  putUpdatePersonio,
  signPersonioActivity,
} from '@/api/personio-connector';
import { ADMIN_ACTIVITY_TYPE } from '@/constants';
import { removeEmptyProps } from '@/utils/helpers';

export default {
  props: {
    companySelected: {
      required: true,
    },
    isEdit: {
      type: Boolean,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;

    const { companySelected } = toRefs(props);
    const existingConfig = ref(null);
    const rerenderKey = ref(0);
    const loading = ref(false);
    const loadedFields = ref([]);
    const loadedFieldsCopy = ref([]);
    const deguraEnums = ref([]);
    const personioValues = ref([]);
    const selectedFields = ref([]);
    const deleteFieldModal = ref(false);
    const canContinue = computed(() => {
      return loadedFields.value.filter((i) => i.company_values.length).length === 0;
    });
    const isEditingSetup = ref(false);
    const headers = computed(() => {
      return [
        {
          text: 'MAPPED FIELDS',
          class: 'text-uppercase border-right',
          value: 'degura_field',
          sortable: false,
        },
        {
          text: 'DEGURA VALUE',
          class: 'text-uppercase',
          value: 'degura_value',
          sortable: false,
        },
        {
          text: 'COMPANY VALUE',
          class: 'text-uppercase',
          value: 'company_field',
          sortable: false,
        },
        {
          text: '',
          class: 'text-uppercase',
          value: 'actions',
          sortable: false,
        },
      ];
    });

    const fetchPersonioCompany = async () => {
      loading.value = true;
      getPersonioCompanyById(companySelected.value.id).then((res) => {
        existingConfig.value = res.data.configJson;

        const promiseArr = [];
        promiseArr.push(getPersonioValues());
        promiseArr.push(getAllDeguraEnumValues());

        Promise.all(promiseArr).then(() => {
          const existingValues = existingConfig.value.fieldEnumMapping;
          const mappedFields = existingConfig.value.targetToSourceFieldMapping;
          const filterEnumFields = Object.keys(mappedFields).filter((i) => Object.keys(deguraEnums.value).includes(i));
          const mappedEnumFields = filterEnumFields.map((key) => ({
            degura_field: key,
            company_field: mappedFields[key],
          }));

          loadedFields.value = mappedEnumFields.map((field) => {
            const companyValuesIndex = personioValues.value.findIndex((v) => v.name === field.company_field);
            const row = {
              ...field,
              degura_values: deguraEnums.value[field.degura_field],
              company_values: personioValues.value[companyValuesIndex].values.map((item) =>
                !item ? 'Not available' : item,
              ),
              mapping: deguraEnums.value[field.degura_field].reduce((a, v) => {
                const existingMapping =
                  existingValues[field.degura_field] && Object.keys(existingValues).length > 0
                    ? Object.keys(existingValues[field.degura_field])
                        .filter((key) => existingValues[field.degura_field][key] === v)
                        .map((item) => (!item ? 'Not available' : item))
                    : '';

                return {
                  ...a,
                  [v]: existingMapping,
                };
              }, {}),
            };

            getAvailableValueOptions(row);
            return row;
          });

          loading.value = false;
        });
      });
    };

    const getPersonioValues = () => {
      return new Promise((resolve, reject) => {
        getPersonioFields(companySelected.value.id)
          .then((res) => {
            personioValues.value = res.data.fields;
            resolve();
          })
          .catch((e) => reject(e));
      });
    };

    const getAvailableValueOptions = (row) => {
      const filteredValues = row.company_values.filter((item) => !Object.values(row.mapping).flat().includes(item));
      row.company_values = filteredValues;
      vm.$emit('validationStatusChange', canContinue.value);
      return filteredValues;
    };

    const deleteChip = (row, field, index) => {
      row.company_values.unshift(row.mapping[field][index]);
      row.mapping[field].splice(index, 1);
      if (row.mapping[field].length === 0) row.mapping[field] = '';
    };

    const getAllDeguraEnumValues = () => {
      return new Promise((resolve, reject) => {
        getDeguraEnums()
          .then((res) => {
            deguraEnums.value = res.data;
            resolve();
          })
          .catch((e) => reject(e));
      });
    };

    const signActivity = () => {
      const userName =
        userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : `${userData.id}`;
      const msg = `${userName} mapped values successful`;
      signPersonioActivity(companySelected.value.id, userData.id, { msg }, ADMIN_ACTIVITY_TYPE.INFO);
    };

    const submitStep = () => {
      if (!canContinue.value) return;

      return new Promise((resolve, reject) => {
        const inverseObj = (obj) => {
          const retobj = {};
          for (const key in obj) {
            obj[key].forEach((v) => {
              if (v === 'Not available') {
                retobj[''] = `${key}`;
              } else {
                retobj[v] = `${key}`;
              }
            });
          }
          return retobj;
        };

        const mappedValues = loadedFields.value.map((field) => {
          return { [field.degura_field]: { ...inverseObj(removeEmptyProps(field.mapping)) } };
        });

        const payload = {
          portalCompanyId: companySelected.value.id,
          fieldEnumMapping: Object.assign({}, ...mappedValues),
          targetToSourceFieldMapping: existingConfig.value.targetToSourceFieldMapping,
          filters: existingConfig.value.filterConfig,
          step: 3,
        };

        putUpdatePersonio(companySelected.value.id, payload)
          .then(() => {
            signActivity();
            resolve();
            vm.$emit('next');
          })
          .catch(() => reject());
      });
    };
    const reset = () => {
      isEditingSetup.value = false;
      // Should reset fields that exist in this step
      return true;
    };
    onMounted(() => {
      fetchPersonioCompany();
    });
    watch(
      () => loadedFields.value,
      (currentValue) => {
        if (loadedFieldsCopy.value.length) {
          const areEqual =
            JSON.stringify(currentValue[0].mapping) === JSON.stringify(loadedFieldsCopy.value[0].mapping);
          areEqual ? (isEditingSetup.value = false) : (isEditingSetup.value = true);
        } else {
          loadedFieldsCopy.value = _.cloneDeep(loadedFields.value);
        }
      },
      { deep: true },
    );
    watch(canContinue, (v) => {
      vm.$emit('validationStatusChange', v);
    });
    return {
      existingConfig,
      loading,
      submitStep,
      deleteChip,
      canContinue,
      rerenderKey,
      selectedFields,
      deleteFieldModal,
      loadedFields,
      headers,
      getAvailableValueOptions,
      isEditingSetup,
      loadedFieldsCopy,
      reset,
      icons: {
        mdiDelete,
        mdiCheckCircle,
        mdiAlertCircle,
        mdiPlusCircle,
        mdiClose,
        mdiInformation,
      },
    };
  },
};
</script>
<style lang="scss">
tr {
  position: relative;
}
.v-data-table {
  tr:hover:not(.v-data-table__expanded__content) {
    background: #ffffff !important;
  }
}

.hidden {
  opacity: 0;
}

.shown {
  opacity: 1;
}

.pb-95 {
  padding-bottom: 85px !important;
  transition: all 0.3s linear;
}

.step-value {
  padding: 0 18px;
  &__btn {
    border-radius: 3px;
    min-width: 230px !important;
  }
  &__warning {
    background: #f98506;
    height: 68px;
    position: absolute;
    //left: 290px;
    transform: translateX(-50%);
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-text {
      color: white;
      max-width: 630px;
      white-space: break-spaces;
    }
  }
  &__info {
    padding: 10px 8px;
    border: 1px solid #2830ce;
    border-radius: 3px;
    margin: 8px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    background-color: white;
    max-width: 245px;
    /*transform: translateX(50%) translateY(10px);*/
    color: #505a68;

    ul {
      padding: 0;
      padding-left: 12px;
    }
    &-title {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #2830ce;
      margin-bottom: 3px;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    max-width: 194px;
    white-space: break-spaces;
  }
  &__chip {
    height: 26px !important;
    margin-bottom: 5px;
    background: rgba(40, 48, 206, 0.15) !important;
    svg {
      color: #2830ce;
    }
  }
  &__input {
    min-width: 320px !important;
    max-width: 320px !important;
    background-color: white !important;
    .v-input__prepend-inner {
      align-items: center;
      display: flex;
      flex: 1 1;
      flex-wrap: wrap;
      line-height: 18px;
      max-width: 100%;
      min-width: 265px;
    }
  }
}
.v-data-table {
  &--personio {
    .v-data-table-header {
      th {
        background: none !important;
        border-top: 0;
        border-bottom-color: #2830ce !important;

        span {
          color: #2830ce;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
